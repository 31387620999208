import React, { Component } from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import 'react-table/react-table.css';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from '@material-ui/icons/Edit';
import Dashboard from '@material-ui/icons/Dashboard';
import RemoveIcon from '@material-ui/icons/Clear';
import DashboardIcon from "@material-ui/icons/Dashboard"
import DownIcon from '@material-ui/icons/ArrowDownward'
import ImageIcon from '@material-ui/icons/Image'
import VideoIcon from '@material-ui/icons/PlayCircleFilled'
import AddIcon from '@material-ui/icons/AddCircle';
import { Card } from "@material-ui/core";
import GridItem from "../../../components/Grid/GridItem.jsx";
import { Player } from 'video-react';
import Modal from '@material-ui/core/Modal';
import { ClipLoader } from 'react-spinners';

// Custom components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import listItemsPageStyle from "../../../assets/views/listItemsPageStyle.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Sidebar from "../../../components/Sidebar/Sidebar.jsx";
import CardBody from "../../../components/Card/CardBody";

import * as mutations from "../../../graphql/mutations"
import config from "../../../config.js"
import AWSAppSyncClient from "aws-appsync";
import awsmobile from '../../../aws-exports';
import gql from 'graphql-tag';
import { Storage, Auth } from "aws-amplify";

import {batchGetItems} from '../../../graphql/queries'

class ClipThisItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // File Upload paramteres
      bucket: config.s3.BUCKET,
      region: "eu-central-1",
      level: "private",

      isLoading: true,
      items: this.props.location.state.items,
      data: [],
      isImageModalOpen: false,
      isVideoModalOpen: false,
      clipThisItems: null,
      mobileOpen: false,
      miniActive: false,
      imageUrl: null,
      videoUrl: null,
      videoFile: null,
      image:null,
      imageHeight:200,
      imageWidth: 200,
      videoHeight: 200,
      videoWidth: 200
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    window.scrollTo(0, 0)
    this.setState({ isLoading: false });

    // Query using a parameter
    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey,
      },
      complexObjectsCredentials: () => Auth.currentCredentials(),
      });
    
    var clipThisIds = {}
    clipThisIds["strings"] = this.props.location.state.items
    console.log(clipThisIds)
    client.query({
      query: gql(batchGetItems),
      variables: {clipThisIds: clipThisIds}
    }).then(({ data: { batchGetItems } }) => {

    const data = batchGetItems.map((prop, key) => {
      return {
        id: key,
        clipThisId: prop.clipThisId,
        userId: prop.userId,
        name: prop.displayName,
        views: prop.views,
        maxViews: prop.maxViews,
        actions: (
          // custom button actions
          <div className="actions-right">
            {/* use this button to add a dashboard kind of action */}
            {/*<Button justIcon round simple onClick={() => {
              // Go to edit item page and feed obj data
              this.props.history.push({
                pathname: "/pages/itemdashboard",
                state:{
                  displayName: prop.displayName,
                  analytics: prop.analytics}
                })
              }}
              color="info"
              className="like"
              >
              <Dashboard />
            </Button>{" "}*/}
            <Button justIcon round simple 
                  onClick={() => {this.handleQRDownload(prop.clipThisId)}}              
                  color="info"
                  className="edit"
                  >
                  <DownIcon />
                </Button>
                {" "}
            <Button justIcon round simple 
                  onClick={() => {this.handleVideoModal(prop.videoFileLocation)}}              
                  color="info"
                  className="edit"
                  >
                  <VideoIcon />
                </Button>
                {" "}
                <Button justIcon round simple 
                  onClick={() => {this.handleImageModal(prop.imageFileLocation)}}             
                  color="info"
                  className="edit"
                  >
                  <ImageIcon />
                </Button>{" "}
                {/* use this button to add a dashboard kind of action */}
                {(["eu-central-1:8c539b15-4d7d-40fc-bd74-1f9b374bf9cb", "eu-central-1:09481023-2f88-402d-a8b1-04716034c419", "eu-central-1:3ab10b62-5486-40a4-bed7-abbd789eba35", "eu-central-1:872de90a-e739-4f6a-9ef1-006ea9fb7149", "eu-central-1:02e20bbc-3c60-48ce-a483-793a763290be"].indexOf(prop.userId)>=0)
                ?
                  <Button justIcon round simple onClick={() => {
                    // Go to edit item page and feed obj data
                    this.props.history.push({
                      pathname: "/pages/itemdashboard",
                      state:{
                        displayName: prop.displayName,
                        analytics: prop.analytics}
                      })
                    }}
                    color="info"
                    className="like"
                    >
                    <DashboardIcon />
                  </Button>
                : ""}
            {/* use this button to add a edit kind of action */}
            <Button justIcon round simple onClick={() => {
                    // Go to edit item page and feed obj data
                    this.props.history.push({
                      pathname: "/edittest",
                      state:{
                        clipThisId: prop.clipThisId,
                        maxViews: prop.maxViews,
                        views: prop.views,
                        analytics: prop.analytics,
                        actionData: prop.actionData,
                        actionType: prop.link,
                        actionName: prop.actionName,
                        displayName: prop.displayName,
                        active: prop.active,
                        premium: prop.premium,
                        email: prop.email,
                        qrposx: prop.qrposx,
                        qrposy: prop.qrposy,
                        createdAt: prop.createdAt,
                        description: prop.description
                        }
                      })
                    }}
                  color="warning"
                  className="edit"
                  >
                  <Edit />
                </Button>{" "}
            {/* use this button to remove the data row */}
            <Button justIcon round simple onClick={() => {
                var data = this.state.data;
                data.find((o, i) => {
                  if (o.id === key) {
                    // here you should add some custom code so you can delete the data
                    // from this component and from your server as well
                    let obj = data.find(o => o.id === key);

                    // delete from server
                    const confirmed = window.confirm(
                      "Diesen Inhalt aus der Kampagne entfernen?"
                    );

                    if (!confirmed) {
                      return;
                    }

                    try {
                      this.removeItem(obj.clipThisId);
                    } catch (e) {
                      alert(e);
                      return;
                    }
                    // delete from data
                    data.splice(i, 1);
                    return true;
                  }
                  return false;
                });
                this.setState({ data: data });
              }}
              color="danger"
              className="remove"
            >
              <RemoveIcon />
            </Button>{" "}
          </div>
        )
      };
    })
    this.setState({ data });
  })
  }

  handleQRDownload = async (clipThisId) => {
    const bucket = "clipthis-qrcodes"
    const region = "eu-central-1"
    const level = "private"
    const qrUrl = await Storage.get(clipThisId+".jpg", { bucket, region, level });

    setTimeout(() => {
      const response = {
        file: qrUrl,
      };
      // now, let's download:
      window.open(response.file);
      // window.location.href = response.file;
    }, 100);
  }

  getImageWidthHeight(url, callback) {
    var img = document.createElement("img");
    img.onload = function() {
      var w  = img.naturalWidth  || img.width;
      var h = img.naturalHeight || img.height;
      callback({width: w, height: h});
    }
    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;
  };

  handleImageModal = async (imageLocation) => {

    var infos = JSON.parse(imageLocation);

    const bucket = this.state.bucket;//infos["bucket"];
    const region = this.state.region;//infos["region"];
    const level = this.state.level;
    let imageKey = infos["key"];
    imageKey = imageKey.split("/")[1];

    try{
      const imageUrl = await Storage.get(imageKey, { bucket, region, level });

      this.setState({
        image: imageUrl
      });

      this.getImageWidthHeight(imageUrl, (result) => {
        var width = 0
        var height = 0
        // Height > Width
        if(result.width <= result.height){
        // Check if height is bigger than screen
        if(result.height>600){
          height = 600
          width = height / result.height * result.width
          }else{
            width = result.width
            height = result.height
          }
        }
        // Width > Height
        else{
          // Check if width is bigger than screen
          if(result.width>600){
            width = 600
            height = width / result.width * result.height
            }
          else{
            width = result.width
            height = result.height
            }
          }
        this.setState({
          imageWidth : width,
          imageHeight: height,
        });
      })

    }catch{console.log("Image Error")};

    this.handleImageOpen();
  };

  handleVideoModal = async (videoLocation) => {
    var infos = JSON.parse(videoLocation);

    const bucket = this.state.bucket;
    const region = this.state.region;
    const level = this.state.level;
    const videoKey = infos["key"];

    try{
      const videoUrl = await Storage.vault.get(videoKey, {bucket, region, level});
      this.setState({
        videoFile: videoUrl,
      });
      // callback function after Video is changed
      this.getVideoWidthHeight(videoUrl, (result) => {
        this.setState({videoWidth : result.width});
        this.setState({videoHeight : result.height});
      });

    }catch{console.log("Video Error")};

    this.handleVideoOpen();
  };

  getVideoWidthHeight(url, callback) {
    var vid = document.createElement("VIDEO");
    vid.onloadedmetadata= function() {
      // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
      // The natural size is the actual image size regardless of rendering.
      // The 'normal' width/height are for the **rendered** size.
      var w  = vid.videoWidth;
      var h = vid.videoHeight;
      callback({width: w, height: h});
    }
    // Setting the source makes it start downloading and eventually call `onload`
    vid.src = url;
  }  
  
  handleImageOpen = () => {
    this.setState({ isImageModalOpen: true });
  };

  handleVideoOpen = () => {
    this.setState({ isVideoModalOpen: true });
  };

  handleClose = () => {
    this.setState({ 
      isImageModalOpen: false,
      isVideoModalOpen: false,
    });
  };

  deleteItem(clipThisId) {
    this.props.onDelete({clipThisId})

    const bucket = this.state.bucket;
    const region = this.state.region;
    const level = this.state.visibility;
    const imageKey = clipThisId + ".jpg";
    const videoKey = clipThisId + ".mp4";
    try{
      Storage.vault.remove(imageKey, { bucket, region, level });
    }catch{
      console.log("Cant delete image from S3");
    };
    try{
      Storage.vault.remove(videoKey, { bucket, region, level });
    }catch{console.log("Cant delete video from S3");};
  };


  async updateCampaign(title, userId, items){

    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey,
      },
      complexObjectsCredentials: () => Auth.currentCredentials(),
    });
    
    const result = await client.mutate({
      mutation: gql(mutations.updateClipThisCampaignsDev),
      variables: {
        input: {
          title,
          userId,
          items
        }
      }
    });
  }

  async cleanCampaign(clipThisId){
    let campaign = {};
    campaign = JSON.stringify(campaign);
    const userId = this.props.location.state.userId;

    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey,
      },
      complexObjectsCredentials: () => Auth.currentCredentials(),
      });
    
    try{
      this.setState({
        isLoading: true
      });
      const edit = true;
      const result = await client.mutate({
        mutation: gql(mutations.updateClipThisItem),
        variables: {
          input: {
            clipThisId,
            campaign,
            userId,
            edit
          }
        }
      });
    }
    catch(e){console.log(e)}
  }

  async editCampaign(clipThisId, items){
    let campaign = {};
    campaign["description"] = this.props.location.state.description;
    campaign["searchKeywords"] = this.props.location.state.searchKeywords;
    campaign["title"] = this.props.location.state.title;
    campaign["userDisplayName"] = this.props.location.state.userDisplayName;
    campaign["isPublic"] = this.props.location.state.isPublic;
    campaign["userId"] = this.props.location.state.userId;
    campaign["tags"] = this.props.location.state.tags;
    campaign["items"] = items;
    campaign = JSON.stringify(campaign);
    const userId = this.props.location.state.userId;

    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey,
      },
      complexObjectsCredentials: () => Auth.currentCredentials(),
      });
    
    try{
      this.setState({
        isLoading: true
      });
      const edit = true;
      const result = await client.mutate({
        mutation: gql(mutations.updateClipThisItem),
        variables: {
          input: {
            clipThisId,
            campaign,
            userId,
            edit
          }
        }
      });
      this.setState({
        isLoading: false
      });
    }
    catch(e){console.log(e)}
  }

  removeItem(clipThisId) {
  
    try { 

      // Get campaign details
      let old_items = this.props.location.state.items;
      console.log("old ", old_items)
      let new_items = old_items.filter(function(value, index, arr){
        return value != clipThisId;
      });
      console.log("new ", new_items)

      let title = this.props.location.state.title;
      let userId = this.props.location.state.userId;

      this.updateCampaign(title, userId, new_items)

      var i;
      for (i=0; i<old_items.length; i++){
        console.log("ID ", old_items[i])
        this.editCampaign(new_items[i], new_items);
      }
      
      this.cleanCampaign(clipThisId);

    } catch{
      console.log("Remove Items error!")
    }
  }

  handleItemClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  };

  // toggleModal = () => {
  //   this.setState({
  //     isModalOpen: !this.state.isModalOpen
  //   });
  //   if(this.state.isModalOpen){
  //     alert("Refresh Page!");
  //   }
  // }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <GridContainer>
          <Sidebar />
        </GridContainer>
        <div className={classes.container}>
          <Card>
            <CardBody>
            <div className={classes.spinner}>
            <ClipLoader
            className={classes.spinner}
            sizeUnit={"px"}
            size={150}
            color={'#123abc'}
            loading={this.state.isLoading}
            /></div>
          <GridContainer className={classes.listItemContainer}>  
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.isImageModalOpen}
            onClose={this.handleClose}
            style={{paddingLeft: (window.innerWidth/2-this.state.imageWidth/2)+"px", paddingTop: (window.innerHeight/2-this.state.imageHeight/2)+"px"}}
            >
            <img src={this.state.image} style={{width: this.state.imageWidth, heigth: this.state.imageHeight}}/>
          </Modal>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.isVideoModalOpen}
              onClose={this.handleClose}
              style={{paddingLeft: (window.innerWidth/2-this.state.videoWidth/2)+"px", paddingTop: (window.innerHeight/2-this.state.videoHeight/2)+"px"}}
              >
              <Player  
                src={this.state.videoFile}
                fluid={false}
                height={250}
                poster={null}/>
            </Modal>
              <GridItem xs={11}>
                <div style={{textAlign: "left"}}>
                  <h2>Inhalte der Kampagne "{this.props.location.state.title}"
                  </h2>
                  </div>
                  </GridItem>
                  <GridItem>
                  <div style={{textAlign: "right"}}>
                <Button justIcon round simple onClick={() => {
                  this.props.history.push({
                    pathname: "/pages/additemtocampaign",
                    state:{
                      userId: this.props.location.state.userId,
                      title: this.props.location.state.title,
                      items: this.props.location.state.items,
                      description: this.props.location.state.description,
                      searchKeywords: this.props.location.state.searchKeywords,
                      userDisplayName: this.props.location.state.userDisplayName,
                      isPublic: this.props.location.state.isPublic,
                      tags: this.props.location.state.tags
                      }
                    })
                  }}
                  color="danger"
                  className="remove"
                >
                  <AddIcon />
                </Button>
                </div>
                </GridItem>
                <GridItem xs={12}>
                <ReactTable
                  data={this.state.data}
                  noDataText={"Die Kampagne hat noch keine Inhalte."}
                  columns={[
                    {
                      Header: "Name",
                      accessor: "name"
                    },
                    {
                      Header: "Views",
                      accessor: "views"
                    },
                    {
                      Header: "Maximale Views",
                      accessor: "maxViews"
                    },
                    {
                      Header: "Aktionen",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultSorted={[
                    {
                      id: "name",
                      desc: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom={true}
                  className={classes.table}
                />
                </GridItem>
          </GridContainer>
          </CardBody>
          </Card>
          </div>
          </div>
      );
  }
}

ClipThisItems.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(listItemsPageStyle)(ClipThisItems)