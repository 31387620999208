import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography"
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import logo from '../../assets/image/logo.png'
import logoBlack from '../../assets/image/logo_black.png'

// Import Material Design Icons
import CampaignIcon from "@material-ui/icons/ImportantDevices";
import AddCampaignIcon from "@material-ui/icons/AddToQueue";
import ItemsIcon from "@material-ui/icons/List";
import AddItemIcon from "@material-ui/icons/PlaylistAdd";
import AnalyticsIcon from "@material-ui/icons/PieChart";
import ContactIcon from "@material-ui/icons/ContactSupport"

// core components
import sidebarStyle from "../../assets/sidebarStyle.jsx"

function ListItemLink(props) {
  return <ListItem button component="a" {...props} style={{paddingTop:"20px"}}/>;
}

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // user: 'Test User...', // TODO: get user
      links: [{name: 'Kampagnen', url: '/pages/listCampaigns', icon: CampaignIcon}, 
              {name: 'Neue Kampagne', url: '/pages/newCampaign', icon: AddCampaignIcon},
              {name: 'Inhalte', url: '/pages/listItems', icon: ItemsIcon},
              {name: 'Neuer Inhalt', url: '/tryitnow', icon: AddItemIcon},
              {name: 'Analytics', url: '/pages/dashboard', icon: AnalyticsIcon},
              {name: 'Kontakt', url: '/pages/contact', icon: ContactIcon},]
    };
  }

  render() {
    const { className, classes } = this.props;
    return (
      <div className={classes.sideNav} style = {{backgroundImage: "/assets/image/sidebar-2.jpg"}}>
        <div className={classes.sideNavOverlay}>
        <img src={logo} 
            alt="Logo" 
            style={{  width: '100px', 
                      justify:"center", 
                      alignItems:"center", 
                      spacing:"0", 
                      margin: 'auto', 
                      display: 'block',
          }} 
            onClick={this.handleLogo}/>

        {/* <span>{this.props.user}</span> */}
        <List className={classes.list}>
          {this.state.links.map((link, key) => {
            return(
              <ListItemLink href={link.url} key={key}>
                <ListItemIcon>
                  <link.icon style={{color:"#fff"}}/>
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={<Typography type="body2" style={{ color: '#FFFFFF', fontSize: "20px", fontWeight:"400"}}>{link.name}</Typography>}
                />
              </ListItemLink>
                  );
          })}
        </List>
        </div>
      </div>
    );  
  }
}

export default withStyles(sidebarStyle)(Sidebar);