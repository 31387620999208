import {
    container,
    defaultFont,
    cardTitle,
    colors,
    appBoxShadow
  } from "./material-dashboard-pro-react.jsx";
  
  const homePageStyle2 = theme => ({
    container,
    content: {
      [theme.breakpoints.up("1224")]: {
        minHeight: "calc(100vh - 80px)",
        position: "relative",
        zIndex: "4",
        marginTop: "71px",
        backgroundColor: "#f9f9f9"
      },
      [theme.breakpoints.down("1224")]: {
        minHeight: "calc(100vh - 80px)",
        position: "relative",
        zIndex: "4",
        backgroundColor: "#f9f9f9"
      },
    },
    partnerContent: {
      [theme.breakpoints.up("1224")]: {
        position: "relative",
        zIndex: "4",
        marginTop: "71px",
        backgroundColor: "#f9f9f9"
      },
      [theme.breakpoints.down("1224")]: {
        position: "relative",
        zIndex: "4",
        backgroundColor: "#f9f9f9"
      },
    },
    mobileContent: {
      [theme.breakpoints.up("1224")]: {
        position: "relative",
        zIndex: "4",
        marginTop: "10px",
        backgroundColor: "#f9f9f9"
      },
      [theme.breakpoints.down("1224")]: {
        position: "relative",
        zIndex: "4",
        backgroundColor: "#f9f9f9"
      },
    },
    wowpostContainer: {
      [theme.breakpoints.up("1224")]: {
        background: "linear-gradient(to bottom,#3f51b5,#ffa500)",
        width: "100vw",
        left: "calc(-50vw + 50%)",
        position: "relative",
        margin: "0",
        height: "600px"
      },
      [theme.breakpoints.down("1224")]: {
        background: "linear-gradient(to bottom,#3f51b5,#ffa500)",
        width: "100vw",
        left: "calc(-50vw + 50%)",
        position: "relative",
        margin: "0",
        height: "600px"
      },
      [theme.breakpoints.down("sm")]: {
        height: "100%"
      },
      
    },
    gridSubContainer: {
      background: "linear-gradient(to bottom,#3f51b5,#ffa500)",
      width: "100vw",
      left: "calc(-50vw + 50%)",
      position: "relative",
      margin: "0",
      height: "600px"
    },
    gridBottomContainer: {
      [theme.breakpoints.up("sm")]: {
      background: "linear-gradient(to bottom,#3f51b5,#ffa500)",
      width: "100vw",
      left: "calc(-50vw + 50%)",
      position: "relative",
      margin: "0",
      height: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        background: "linear-gradient(to bottom,#3f51b5,#ffa500)",
        width: "100vw",
        left: "calc(-50vw + 50%)",
        position: "relative",
        margin: "0",
        height: "600px",
      }
    },
    gridContainer: {
      [theme.breakpoints.up("sm")]: {
        width: "100vw",
        left: "calc(-50vw + 50%)",
        position: "relative",
        paddingBottom: "100px",
      }, 
      [theme.breakpoints.down("sm")]: {
        width: "100vw",
        position: "relative",
        paddingBottom: "100px",
      },
    },
    gridContainerWhite: {
      [theme.breakpoints.up("sm")]: {
        background: "#fff",
        width: "100vw",
        left: "calc(-50vw + 50%)",
        position: "relative",
        paddingBottom: "100px"
      },
      [theme.breakpoints.down("sm")]: {
        background: "#fff",
        width: "100vw",
        position: "relative",
        paddingBottom: "100px"
      }, 
    },
    footerStoreImg: {
      padding: "0",
      width: " 150px",
      height: "350",
    },
    wowPostText:{
      [theme.breakpoints.up("1224")]: {
        marginRight: "50px",
      },
      
      [theme.breakpoints.down("1224")]: {
        textAlign: "center",
        paddingTop: "50px",
      },
    },
    videoPlayerText:{
      [theme.breakpoints.up("1224")]: {
        marginLeft: "50px",
        marginRight: "50px"
      },
    },
    wowPostItems: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("1224")]: {
        textAlign: "center"
      },
    },
    title: {
      ...defaultFont,
      color: "#242424",
      marginTop: "13vh",
      marginBottom: "10px",
      textAlign: "center",
      fontWeight: "400"
    },
    subTitle: {
      ...defaultFont,
      color: "#242424",
      marginTop: "0px",
      marginBottom: "10px",
      textAlign: "center",
      fontWeight: "400"
    },
    subTitleSm: {
      ...defaultFont,
      color: "#242424",
      marginTop: "0px",
      marginBottom: "10px",
      textAlign: "center",
      fontWeight: "400",
      [theme.breakpoints.down("sm")]: {
        ...defaultFont,
        color: "#242424",
        marginTop: "20px",
        marginBottom: "10px",
        textAlign: "center",
        fontWeight: "400",
        fontSize: "18px"
      },
    },
    titleMobile:{
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "32px",
      fontWeight: "bold",
      lineHeight: "1.33",
      color: "#8155FB",
      marginTop: "24px",
      marginBottom: "20px",
      textAlign: "left",
    },
    description: {
      fontSize: "18px",
      color: "#FFFFFF",
      textAlign: "center"
    },
    descriptionMobile: {
      fontSize: "16px",
      color: "#3A4649",
    },
    iconBulletMobile: {
      fontSize: "12px",
      color: "#3A4649",
      verticalAlign: "middle"
    },
    bulletsMobile: {
      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "1.33"
    },
    button: {
      backgroundColor: "#fff", 
      width: "auto",
      height: "auto",
      color: "#08f", 
      fontWeight: "700", 
      fontSize: "16px",
      boxShadow: "none",
      borderRadius: "5px",
      padding: "15px 15px",
      margin: "auto",
      textAlign: "center",
      "&:hover,&:focus": {
        backgroundColor: "#fff",
        color: "#08f",
        boxShadow: "none"
      },
    },
    homeButtons:{
      [theme.breakpoints.down("1224")]: {
        textAlign: "center"
      },
    },
    centeredButton:{
      align: "center"
    },
    buttonMobile: {
      marginTop:"20px"
    },
    buttonDescriptionMobile: {
      fontSize: "16px",
      color: "#fff",
      fontWeight:"400"
    },
    gridItemImg: {
      [theme.breakpoints.down("1224")]: {
        textAlign: "center"
      },
      [theme.breakpoints.down("sm")]: {
        order: "2",
        textAlign: "center"
      },
    },
    homePageImg: {
      [theme.breakpoints.up("1224")]: {
        width: "562px", 
        height: "600px", 
        marginTop: "60px",
        marginRight:"68px", 
        float: "right",
        maxWidth: "100%"
      },
      [theme.breakpoints.down("1224")]: {
        height: "81vh", 
        minHeight: "75vh",
        marginTop: "60px",
        maxWidth: "100%"
      },
      [theme.breakpoints.down("sm")]: {
        width: "auto", 
        height: "44vh", 
        minHeight: "40vh"
      },
    },
    homePageImgMac: {
      [theme.breakpoints.up("1224")]: {
        width: "630px", 
        height: "378px", 
        marginTop: "140px",
        marginRight:"68px", 
        marginBottom: "0px",
        float: "right"
      },
      [theme.breakpoints.down("1224")]: { 
        marginTop: "140px", 
        marginBottom: "0px",
        maxWidth: "100%"
      },
      [theme.breakpoints.down("sm")]: { 
        marginTop: "60px", 
        marginBottom: "0px",
        maxWidth: "100%"
      },
    },
    homePageImgMonkey: {
      [theme.breakpoints.up("1224")]: {
        width: "630px", 
        height: "378px", 
        marginTop: "140px",
        marginRight:"0", 
        marginBottom: "0px",
        float: "left"
      },
      [theme.breakpoints.down("1224")]: { 
        marginTop: "140px", 
        marginBottom: "0px",
        maxWidth: "100%"
      },
      [theme.breakpoints.down("sm")]: { 
        marginTop: "60px", 
        marginBottom: "0px",
        maxWidth: "100%"
      },
    },
    bottomImg: {
      [theme.breakpoints.up("sm")]: {
      width: "100%", 
      float: "center"
      },
      marginBottom: "0.1px",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    homePageImgAnalytics: {
      [theme.breakpoints.up("1224")]: {
        width: "677px", 
        height: "420px", 
        marginTop: "60px",
        marginRight:"68px", 
        float: "right"
      },
      [theme.breakpoints.down("1224")]: {
        width: "100%", 
        height: "100%", 
        marginTop: "60px", 
        maxWidth: "100%"
      }
    },
    header: {
      [theme.breakpoints.up("1224")]: {
      fontSize: "60px",
      fontWeight: "700",
      color: "#fff",
      textAlign: "left",
      marginBottom: "40px",
      lineHeight: "1"
    },
      [theme.breakpoints.down("1224")]: {
        fontSize: "30px",
        fontWeight: "700",
        color: "#fff",
        marginBottom: "40px",
        lineHeight: "1",
      },
    },
    headerBlack: {
      [theme.breakpoints.up("1224")]: {
        fontSize: "40px",
        fontWeight: "700",
        color: "#000",
        textAlign: "left",
        marginBottom: "40px",
        lineHeight: "1",
      },
      [theme.breakpoints.down("1224")]: {
        fontSize: "30px",
        fontWeight: "700",
        color: "#000",
        textAlign: "center",
        marginBottom: "30px",
        lineHeight: "1",
      },
    },
    cardTitle: {
      [theme.breakpoints.up("1224")]: {
        color: "#fff",
        fontSize: "18px",
        lineHeight: "28px",
        fontWeight: "400"
    },
      [theme.breakpoints.down("1224")]: {
        color: "#fff",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400",
        padding: "0 20px"
    },
  },
    cardSubTitle: {
      [theme.breakpoints.up("1224")]: {
        color: "#000",
        fontSize: "18px",
        lineHeight: "28px",
        fontWeight: "400"
    },
      [theme.breakpoints.down("1224")]: {
        color: "#000",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400",
        padding: "0 20px"
    },
  },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    },
    cardCategory: {
      color: "#999999",
      marginTop: "10px",
      fontSize: "16px"
    },
    cardCategoryWhite: {
      color: "#FFFFFF",
      marginTop: "10px"
    },
    cardMobile: {
      marginBottom: "0px "
    },
    icon: {
      color: "#333333",
      margin: "10px auto 0",
      width: "130px",
      height: "130px",
      border: "1px solid #005da6",
      borderRadius: "50%",
      lineHeight: "174px",
      "& svg": {
        width: "55px",
        height: "55px"
      }
    },
    iconTitle:{
      color: "#242424",
      textAlign: "center",
      fontSize: "20px"

    },
    iconWhite: {
      color: "#005da6"
    },
    iconRose: {
      color: colors.roseColor
    },
    label: {
      cursor: "pointer",
      paddingLeft: "0",
      color: "rgba(0, 0, 0, 0.26)",
      fontSize: "12px",
      lineHeight: "1.428571429",
      fontWeight: "400",
      display: "inline-flex"
    },
    homeVideoPlayer: {
      [theme.breakpoints.down("1224")]: {
        justifyContent: "center",
        display: "flex",
        marginTop: "60px"
      },
    },
    homePageGif: {
      width: "100%"
    },
    imgWrap:{
      position: "relative",
      // verticalAlign: "middle",
      // display: "table-cell",
    },

    imgDescription: {
      height: "100%",
      width: "100%",
      textAlign: "center",
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      background: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      // visibility: "hidden",
      opacity: "0",
      transition: "opacity .2s, visibility .2s",
      boxShadow: "15px 15px 15px -3px rgba(0, 0, 0, 0.3)",
      // padding: "8px",
      "&:hover": {
        visibility: "visible",
        opacity: "1"
      }
    }, 
    textOnHover: {
      transform: "translate(-50%, -50%)",
      // -webkit-transition: all 0.2s ease-in-out 0s;
      transition: "all 0.2s ease-in-out 0s",
      position: "absolute",
      textAlign: "center",
      paddingLeft: "1em",
      paddingRight: "1em",
      width: "100%",
      top: "50%",
      left: "50%"
    },

    headerGif: {
      [theme.breakpoints.down("1224")]: {

      },
      fontSize: "1.3em",
      marginBottom: "14px",
      fontWeight: "300",
      marginTop: "3px"
    },

    paragraphGif: {
      [theme.breakpoints.down("1224")]: {

      }

    },

    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    }
});

export default homePageStyle2;