import React, { Component } from "react";
import MediaQuery from 'react-responsive';
import cx from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import logoBlack from './assets/image/logo_black.png'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons'

import {withRouter, Switch, Route} from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Button from "./components/CustomButtons/Button";
import LoginPage from "./views/Pages/LoginRegister/LoginPage.jsx";
import RegisterPage from "./views/Pages/LoginRegister/RegisterPage.jsx";
import ForgotPasswordPage from "./views/Pages/LoginRegister/ForgotPassword.jsx";
import AGBPage from "./views/Pages/ExtraPages/AGB.jsx";
import DatenschutzPage from "./views/Pages/ExtraPages/Datenschutz.jsx";
import Dashboard from "./views/Pages/WebApp/Dashboard"; 
import Contact from "./views/Pages/ExtraPages/Contact";
import NotFound from "./views/Pages/ExtraPages/NotFound";
import ConfirmationPage from "./views/Pages/ExtraPages/ConfirmationPage.jsx"
import EditItem from "./views/Pages/WebApp/EditItem.jsx"
import ListItems from "./views/Pages/WebApp/ListItems.jsx";
import NewCampaign from "./views/Pages/WebApp/NewCampaign.jsx"
import ListCampaigns from "./views/Pages/WebApp/ListCampaigns.jsx"
import EditCampaign from "./views/Pages/WebApp/EditCampaign.jsx"
import ListCampaignItems from "./views/Pages/WebApp/ListCampaignItems.jsx"
import ItemDashboard from "./views/Pages/WebApp/ItemDashboard.jsx"
import TestNow from "./views/Pages/WebApp/TesterPageCreate.jsx"
import EditTest from "./views/Pages/WebApp/TesterPageEdit.jsx"
import AddItemToCampaign from "./views/Pages/WebApp/AddItemToCampaign.jsx"

import DashboardIcon from "@material-ui/icons/Dashboard";
import SearchIcon from "@material-ui/icons/Search";

import {Navbar, Nav, NavItem,} from "react-bootstrap"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// core components
import Footer from "./components/Footer/Footer.jsx";
import Header from "./components/Header/Header.jsx"
import pagesStyle from "./assets/layouts/pagesStyle2.jsx";

import "./App.css";
import { Auth } from "aws-amplify";

library.add(faStroopwafel)

class App extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      anchorEl: null,
      userName: "empty name",
      email: "empty email",
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogo = this.handleLogo.bind(this);
  }

  componentWillMount(){
    var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;
    
    this.setState({x:x,y:y});
  }

  async componentDidMount() {
    
    try {
      await Auth.currentAuthenticatedUser().then(user => 
        this.userHasAuthenticated(true, user.attributes.email)
        )
      .catch();

      try{
        const { identityId }  = await Auth.currentCredentials()
        this.setState({
          userName: identityId
        });
      }
      catch(e){}

    } catch (e) {
      if (e !== "not authenticated") {
        alert(e);
      }
    }
  
    this.setState({ isAuthenticating: false });
  }
  
  userHasAuthenticated = (authenticated, email) => {
    console.log(email)
    if(email !== "null"){
      console.log(authenticated)
      this.setState({ 
        isAuthenticated: authenticated,
        email: email
      })
    }else{
      this.setState({
        isAuthenticated: authenticated
      });
    };
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false, "null");
    this.props.history.push("/");
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleLogo = event => {
    this.props.history.push("/")
  };

  handleClose(path){
    this.props.history.push({
      pathname: path
      });
  };
 
  render() {
    
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      userName: this.state.userName,
      email: this.state.email
    };
    const { classes, color} = this.props;

    const appBarClasses = cx({
      [" " + classes[color]]: color
    });

    // For mobile
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const dashboardRoutes = [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: DashboardIcon,
        component: Dashboard
      },
      {
        collapse: true,
        path: "-page",
        name: "Pages",
        state: "openPages",
        icon: SearchIcon,
        //views: pages
      }
    ]

    return (
      !this.state.isAuthenticating &&
      <div>
        <MediaQuery minWidth={1224}>
        <div style={{backgroundColor: "rgba(0, 0, 0, 0.65)"}}>
          {!this.state.isAuthenticated
            ? <div></div>
              :<div style={{backgroundColor:"#3c4858"}}>
                <Header
                  handleLogout={this.handleLogout}
                />    
              </div>
            }
            </div>

      <div className={classes.wrapper} ref="wrapper">
        <div className={classes.fullPage}>
          <Switch>
            <UnauthenticatedRoute path="/" exact component={LoginPage} props={childProps} />
            <AppliedRoute path="/pages/agb" exact component={AGBPage} props={childProps} />
            <AppliedRoute path="/pages/datenschutz" exact component={DatenschutzPage} props={childProps} />
            <AuthenticatedRoute path="/pages/edititem" exact component={EditItem} props={childProps} />
            <AuthenticatedRoute path="/pages/itemdashboard" exact component={ItemDashboard} props={childProps} />
            <AuthenticatedRoute path="/tryitnow" exact component={TestNow} props={childProps} />
            <AuthenticatedRoute path="/edittest" exact component={EditTest} props={childProps} />
            <AuthenticatedRoute path="/pages/dashboard" exact component={Dashboard} props={childProps} />
            <UnauthenticatedRoute path="/pages/login" exact component={LoginPage} props={childProps} />
            <UnauthenticatedRoute path="/pages/register" exact component={RegisterPage} props={childProps} />
            <UnauthenticatedRoute path="/pages/forgotpassword" exact component={ForgotPasswordPage} props={childProps}/>
            <AuthenticatedRoute path="/pages/listitems" exact component={ListItems} props={childProps} />
            <AuthenticatedRoute path="/pages/newCampaign" exact component={NewCampaign} props={childProps} />
            <AuthenticatedRoute path="/pages/listcampaigns" exact component={ListCampaigns} props={childProps} />
            <AuthenticatedRoute path="/pages/editcampaign" exact component={EditCampaign} props={childProps} />
            <AuthenticatedRoute path="/pages/listcampaignitems" exact component={ListCampaignItems} props={childProps} />
            <AuthenticatedRoute path="/pages/additemtocampaign" exact component={AddItemToCampaign} props={childProps} />
            <AuthenticatedRoute path="/pages/confirmation" exact component={ConfirmationPage} props={childProps} />
            <AppliedRoute path="/pages/contact" exact component={Contact} props={childProps} />

            { /* Finally, catch all unmatched routes */ }
            <Route component={NotFound} />
          </Switch>
          <CookieConsent
            location="bottom"
            buttonText="Verstanden"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            >
            Diese Website verwendet Cookies für Analysen, personalisierte Inhalte und Werbung. Indem Sie diese Website nutzen, erklären Sie sich mit dieser Verwendung einverstanden.
          </CookieConsent>
          <Footer white />
        </div>
      </div>
      </MediaQuery>

      <MediaQuery maxWidth={1224}>
        <div style={{backgroundColor:"#F5F5F7"}}>
        <Navbar collapseOnSelect style={{backgroundcolor:"#F5F5F7", marginBottom:"0px"}}>
          <Navbar.Header>
            <Navbar.Brand>
              <div><img src={logoBlack} alt="Logo" style={{width: '40px'}} onClick={this.handleLogo}/></div>
            </Navbar.Brand>
            <Navbar.Toggle style={{color:"#000000", marginTop:"20px", border:"0"}}/>
          </Navbar.Header>
          <Navbar.Collapse>
            {this.state.isAuthenticated
              ?  
            <Nav>
                <NavItem key="home" selected={true} onClick={() => this.handleClose("/")}>Home</NavItem>
                <NavItem key="kontakt" selected={false} onClick={() => this.handleClose("/pages/contact")}>Kontakt</NavItem>
                <NavItem key="logout" selected={false} onClick={this.handleLogout}>Logout</NavItem>
              </Nav>
              :
              <Nav>
                <NavItem key="home" selected={true} onClick={() => this.handleClose("/")}>Home</NavItem>
                <NavItem key="kontakt" selected={false} onClick={() => this.handleClose("/pages/contact")}>Kontakt</NavItem>
              </Nav>}
          </Navbar.Collapse>
        </Navbar>
          <div className={classes.wrapper} ref="wrapper">
          <div className={classes.fullPageMobile}>
            <Switch>
              <AppliedRoute path="/" exact component={LoginPage} props={childProps} />
              <AuthenticatedRoute path="/tryitnow" exact component={TestNow} props={childProps} />
              <AuthenticatedRoute path="/pages/listitems" exact component={ListItems} props={childProps} />
              <AuthenticatedRoute path="/pages/newCampaign" exact component={NewCampaign} props={childProps} />
              <AuthenticatedRoute path="/pages/listcampaigns" exact component={ListCampaigns} props={childProps} />
              <AuthenticatedRoute path="/pages/editcampaign" exact component={EditCampaign} props={childProps} />
              <AuthenticatedRoute path="/pages/listcampaignitems" exact component={ListCampaignItems} props={childProps} />
              <UnauthenticatedRoute path="/pages/agb" exact component={AGBPage} props={childProps} />
              <UnauthenticatedRoute path="/pages/datenschutz" exact component={DatenschutzPage} props={childProps} />
              <UnauthenticatedRoute path="/pages/dashboard" exact component={Dashboard} props={childProps} />
              <UnauthenticatedRoute path="/pages/login" exact component={LoginPage} props={childProps} />
              <UnauthenticatedRoute path="/pages/register" exact component={RegisterPage} props={childProps} />
              <UnauthenticatedRoute path="/pages/contact" exact component={Contact} props={childProps} />
              { /* Finally, catch all unmatched routes */ }
              <Route component={NotFound} />
            </Switch>
            <Footer white />
          </div>
          </div>
          </div>
        </MediaQuery>
      </div>
      
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};


export default withStyles(pagesStyle)(withRouter(App));