import React from "react";
import PropTypes from "prop-types";
import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import MediaQuery from 'react-responsive';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import Textfield from "@material-ui/core/TextField";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardText from "../../../components/Card/CardText.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";

import regularFormStyle from "../../../assets/views/regularFormsStyle";
import LoaderButton from "../../../components/LoaderButton.js";
import Sidebar from "../../../components/Sidebar/Sidebar.jsx";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      isLoading: false,
      firmenname: "empty",
      firmenwebsite: "empty",
      ansprechperson: "",
      email: "",
      telefonnummer:"",
      branche: "",
      anliegen:"",
      isAuthenticated:false
    };

    this.contactRequest = this.contactRequest.bind(this);
  }

  //componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    //setTimeout(
      //function() {
        //this.setState({ cardAnimaton: "" });
      //}.bind(this),
     // 700
    //);
  //}

  validateForm() {
    return(
      this.state.email.length > 0,
      this.state.anliegen.length > 0,
      this.state.telefonnummer.length > 0,
      this.state.ansprechperson.length > 0);
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleToggle(value) {
    const branchen = ["Branche1", "Branche2"];
    this.setState({
      branche: branchen.indexOf(value)
    });
  }

  async componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    try {
      if (await Auth.currentCredentials()) {
        this.setState({isAuthenticated:true});
        console.log("current Credentials worked")
      }
    }
    catch(e) {
      console.log("Did not work")
      if (e !== 'No current user') {
        console.log("No current user")
      }
    }
  }

  // API post to create new item
  contactRequest(item){

    return API.post("clpthis", "/clpthis-contact", {
      body: item
      });
  } 

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
    // Wait until new database entry is created successfully
    await this.contactRequest({
      firmenname: this.state.firmenname,
      firmenwebsite: this.state.firmenwebsite,
      ansprechperson: this.state.ansprechperson,
      email: this.state.email,
      telefonnummer: this.state.telefonnummer,
      branche: "not defined",//this.state.branche,
      anliegen: this.state.anliegen      
      });
    
      alert("Vielen Dank für Ihre Nachricht. Wir melden uns in der Regeln in 1-2 Tagen bei Ihnen.")
    // Redirect to payment
    this.props.history.push("/");
    } catch (e) {
      alert(e);
      // Turn of spinner loader
      this.setState({ isLoading: false });
    }
  };
      
  render() {
    const { classes } = this.props;
    return (
      <div>
        <MediaQuery minWidth={1224}>
          <div className={classes.content}>
          {this.state.isAuthenticated
          ? <GridContainer>
              <Sidebar />
            </GridContainer>
          : <div></div>
          }
            <div className={classes.container}>
              <form onSubmit={this.handleSubmit}>
                <GridContainer className={classes.listItemContainer}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card className={classes[this.state.cardAnimaton]}>
                      <CardBody>
                      <h4>Kontaktformular</h4>
                        <GridContainer>
                          <GridItem xs={12} sm={2}>
                              <FormLabel className={classes.labelHorizontal}>
                              Firmenname
                              </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                              <CustomInput
                              id="firmenname"
                              value={this.state.firmenname}
                              onChange={this.handleChange}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              inputProps={{
                                  type: "text"
                              }}
                              />
                          </GridItem>
                          </GridContainer>
                          <GridContainer>
                          <GridItem xs={12} sm={2}>
                              <FormLabel className={classes.labelHorizontal}>
                              Firmenwebsite
                              </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                              <CustomInput
                              id="firmenwebsite"
                              value={this.state.firmenwebsite}
                              onChange={this.handleChange}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              inputProps={{
                                  type: "text"
                              }}
                              />
                          </GridItem>
                          </GridContainer>
                          <GridContainer>
                          <GridItem xs={12} sm={2}>
                              <FormLabel className={classes.labelHorizontal}>
                              Ansprechperson*
                              </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                              <CustomInput
                              id="ansprechperson"
                              value={this.state.ansprechperson}
                              onChange={this.handleChange}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              inputProps={{
                                  type: "text"
                              }}
                              helpText="A block of help text that breaks onto a new line."
                              />
                          </GridItem>
                          </GridContainer>
                          <GridContainer>
                          <GridItem xs={12} sm={2}>
                              <FormLabel className={classes.labelHorizontal}>
                              Email Adresse*
                              </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                              <CustomInput
                              id="email"
                              value={this.state.email}
                              onChange={this.handleChange}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              inputProps={{
                                  type: "text"
                              }}
                              />
                          </GridItem>
                          </GridContainer>
                          <GridContainer>
                          <GridItem xs={12} sm={2}>
                              <FormLabel className={classes.labelHorizontal}>
                              Telefonnummer*
                              </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                              <CustomInput
                              id="telefonnummer"
                              value={this.state.telefonnummer}
                              onChange={this.handleChange}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              inputProps={{
                                  type: "text"
                              }}
                              />
                          </GridItem>
                          </GridContainer>
                          <GridContainer>
                          <GridItem xs={12} sm={2}>
                              <FormLabel className={classes.labelHorizontal}>
                              Anliegen*
                              </FormLabel>
                          </GridItem>
                          <GridItem xs={12} sm={10}>
                              <Textfield
                              id="anliegen"
                              value={this.state.anliegen}
                              onChange={this.handleChange}
                              
                              inputProps={{
                                  type: "textfield"
                              }}
                              fullWidth
                              multiline
                              rows="15"
                              />
                          </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={12} sm={2}>
                              <LoaderButton
                                block
                                bsSize="large"
                                type="submit"
                                disabled={!this.validateForm()}
                                isLoading={this.state.isLoading}
                                text="Absenden"
                                loadingText="Sendet…"
                              />
                            </GridItem>
                          </GridContainer>
                      
                          </CardBody>
                      </Card>
                      </GridItem>
                </GridContainer>
              </form>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <div className={classes.contentMobile}>
            <div className={classes.container}>
              <form onSubmit={this.handleSubmit}>
                <GridContainer justify="center">
                  <GridItem xs={12}>
                    <h2 className={classes.titleMobile}>Kontaktformular</h2>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontalMobile}>
                          Firmenname
                          </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                          <CustomInput
                          id="firmenname"
                          value={this.state.firmenname}
                          onChange={this.handleChange}
                          formControlProps={{
                              fullWidth: true,
                              className: classes.formControlMobile
                          }}
                          inputProps={{
                              type: "text",
                              className: classes.formControlInputMobile
                          }}
                          />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontalMobile}>
                          Firmenwebsite
                          </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                          <CustomInput
                          id="firmenwebsite"
                          value={this.state.firmenwebsite}
                          onChange={this.handleChange}
                          formControlProps={{
                              fullWidth: true,
                              className: classes.formControlMobile
                          }}
                          inputProps={{
                              type: "text",
                              className: classes.formControlInputMobile
                          }}
                          />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontalMobile}>
                          Ansprechperson*
                          </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                          <CustomInput
                          id="ansprechperson"
                          value={this.state.ansprechperson}
                          onChange={this.handleChange}
                          formControlProps={{
                              fullWidth: true,
                              className: classes.formControlMobile
                          }}
                          inputProps={{
                              type: "text",
                              className: classes.formControlInputMobile
                          }}
                          helpText="A block of help text that breaks onto a new line."
                          />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontalMobile}>
                          Email Adresse*
                          </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                          <CustomInput
                          id="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          formControlProps={{
                              fullWidth: true,
                              className: classes.formControlMobile
                          }}
                          inputProps={{
                              type: "text",
                              className: classes.formControlInputMobile
                          }}
                          />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontalMobile}>
                          Telefonnummer*
                          </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                          <CustomInput
                          id="telefonnummer"
                          value={this.state.telefonnummer}
                          onChange={this.handleChange}
                          formControlProps={{
                              fullWidth: true,
                              className: classes.formControlMobile
                          }}
                          inputProps={{
                              type: "text",
                              className: classes.formControlInputMobile
                          }}
                          />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={2}>
                          <FormLabel className={classes.labelHorizontalMobile}>
                          Anliegen*
                          </FormLabel>
                      </GridItem>
                      <GridItem xs={12} sm={10}>
                          <Textfield className={classes.formControlMobile}
                          id="anliegen"
                          value={this.state.anliegen}
                          onChange={this.handleChange}
                          
                          inputProps={{
                              type: "textfield",
                          }}
                          InputProps={{
                            className: classes.formControlInputMobile
                          }}
                          fullWidth
                          multiline
                          rows="10"
                          />
                      </GridItem>
                    </GridContainer>
                    <LoaderButton
                      className={classes.loaderButtonMobile}
                      block
                      bsSize="large"
                      type="submit"
                      disabled={!this.validateForm()}
                      isLoading={this.state.isLoading}
                      text="Absenden"
                      loadingText="Sendet…"
                    />
                  </GridItem>
                </GridContainer>
              </form>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(regularFormStyle)(Contact);
