import React, { Component } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { Storage, Auth } from 'aws-amplify';
import config from "../../../config";

import {Image, FormGroup, FormControl} from "react-bootstrap";
import { Player } from 'video-react';
import {Rnd} from "react-rnd";  // Move image libary
import "../../../containers/DragQRCode.css"  // Needed for the libary used to make the qr code dragable
import Button from "../../../components/CustomButtons/Button.jsx";
import { ClipLoader } from 'react-spinners';
import qrcode from '../../../assets/image/qrcode.png'

// Custom components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import BasicCustomInput from "../../../components/BasicCustomInput/BasicCustomInput.jsx";

import privateUploadPageStyle from "../../../assets/views/privateUploadPageStyle.jsx";

import awsmobile from '../../../aws-exports';
import AWSAppSyncClient from "aws-appsync";
import gql from 'graphql-tag'
import * as mutations from "../../../graphql/mutations"

// https://serverless-stack.com/chapters/display-a-note.html

class EditItem extends Component {
  constructor(props) {
    super(props);

    this.fileVideo = null;
    this.fileImage = null;

    this.state = {
      bucket: "clipthis-app-api-prod-attachmentsbucket-x8ozzjtziq7v",
      region: "eu-central-1",
      level: "private",
      isLoading: null,
      isDeleting: null,
      imageWidth: 200,
      imageHeight: 200,
      item: null,
      attachmentURL: null,
      imageChanged: false,
      imageFile: null,
      qrSize: 50,

      clipThisId: this.props.location.state.clipThisId,
      userId: this.props.userName,
      email: this.props.location.state.email,
      maxViews: this.props.location.state.maxViews,
      views: this.props.location.state.views,
      analytics: this.props.location.state.analytics,
      actionData: this.props.location.state.actionData,
      actionType: this.props.location.state.link,
      actionName: this.props.location.state.actionName,
      displayName: this.props.location.state.displayName,
      active: this.props.location.state.active,
      premium: this.props.location.state.premium,
      qrposx: 0,
      qrposy: 0,
      createdAt: this.props.location.state.createdAt,
      description: this.props.location.state.description,
      updatedAtList: [],
      edit: true
    };      
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    const bucket = this.state.bucket;
    const region = this.state.region;
    const level = this.state.level;
    const imageKey = this.state.clipThisId + ".jpg";
    const videoKey = this.state.clipThisId + ".mp4";

    try{
      const imageUrl = await Storage.vault.get(imageKey, { bucket, region, level });
      this.setState({
        imageFile: imageUrl
      });
      this.fileImage = imageUrl;
      // Callback function after Image is changed
      this.getImageWidthHeight(imageUrl, (result) => {
      var width = 0
      var height = 0
      var qrSize = 0
      // Height > Width
      if(result.width <= result.height){
        // Check if height is bigger than screen
        if(result.height>1200){
          height = 1200
          width = height / result.height * result.width
          }else{
            width = result.width
            height = result.height
          }
        qrSize = 0.2*width;
        }
      // Width > Height
      else{
        // Check if width is bigger than screen
        if(result.width>600){
          width = 600
          height = width / result.width * result.height
          }
        else{
          width = result.width
          height = result.height
          }
        qrSize = 0.2*height;
        }
      this.setState({
        qrSize: qrSize,
        imageWidth : width,
        imageHeight: height,
        });
      });
    }
    catch (e) {
      alert(e);
    }
    
    try{
      const videoUrl = await Storage.vault.get(videoKey, {bucket, region, level});
      this.setState({
        videoFile: videoUrl,
        videoPoster: null,
      });
      this.videoFile = videoUrl;
    }
    catch(e){
      alert(e);
    }
  };

  // Extracts width and height from video and first image of video to set as poster in player
  getVideoInfos(url, callback) {
    var video = document.createElement("VIDEO");

    video.onloadeddata = function(e) {
      var canvas = document.createElement('canvas');
      var w = video.videoWidth;
      var h = video.videoHeight;
      canvas.height = h;
      canvas.width = w;
      var ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      var img = new Image();
      img.src = canvas.toDataURL();
      callback({width: w, height: h, image: img, error: e});
    };
    video.onerror = function(e) {
      callback.call(undefined, undefined, undefined, e);
    };
    // Setting the source makes it start downloading and eventually call `onload`
    video.src = url;
  }

   // Called when user chooses video. Sets new video, triggers getting of width and height.
   handleVideoFileChange = event => {
    this.fileVideo = event.target.files[0];
    if (this.fileVideo.type !== "video/mp4") {
      alert(`Please pick an video with the format .mp4.`);
      return;
    }
    let url = URL.createObjectURL(this.fileVideo);

    let currentList = this.state.updatedAtList;
    currentList.push("videoFile");

    this.setState({
      videoFile: url,
      videoChanged: true,
      videoPoster: null,
      videoFrameHeight: 250,
      updatedAtList: currentList
    });
  
    // callback function after video is changed
    this.getVideoInfos(url, (result) => {
      this.setState({videoWidth : result.width});
      this.setState({videoHeight : result.height});
      //this.setState({videoPoster: result.image.path});
      this.setState({videoWasChanged: true})
    });
  };

  // Extracts width and height of image
  getImageWidthHeight(url, callback) {
    var img = document.createElement("img");
    img.onload = function() {
      var w  = img.naturalWidth  || img.width;
      var h = img.naturalHeight || img.height;
      callback({width: w, height: h});
    }
    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;
  };

  // Called when user changes new image. Triggers width, height extraction
  // Triggers quality check of image
  handleImageFileChange = event => {
    this.fileImage = event.target.files[0];
    let url = URL.createObjectURL(this.fileImage);

    let currentList = this.state.updatedAtList;
    currentList.push("file");

    this.setState({
      imageFile: url,
      imageChanged: true,
      qrposx: 0,
      qrposy:0,
      updatedAtList: currentList
    });

    // Check Image Quality
    //this.f(this.fileImage).then(alert('Done'))

    // Callback function after Image is changed
    this.getImageWidthHeight(url, (result) => {
      var width = 0
      var height = 0
      var qrSize = 0
      // Height > Width
      if(result.width <= result.height){
        // Check if height is bigger than screen
        if(result.height>1200){
          height = 1200
          width = height / result.height * result.width
          }else{
            width = result.width
            height = result.height
          }
        qrSize = 0.2*width;
        }
      // Width > Height
      else{
        // Check if width is bigger than screen
        if(result.width>600){
          width = 600
          height = width / result.width * result.height
          }
        else{
          width = result.width
          height = result.height
          }
        qrSize = 0.2*height;
        }
      this.setState({
        qrSize: qrSize,
        imageWidth : width,
        imageHeight: height,
      });
    });
  };

  handleChange = name => event => {
    let currentList = this.state.updatedAtList;
    if(currentList.indexOf(name) > -1){
      currentList.push(name);
    }
    
    this.setState({
      [name]: event.target.value,
      updatedAtList: currentList 
    });
  };

  handleCheckboxChoice = name => event => {
    var value = event.target.checked;
    this.setState({ [name]: value });
  };

  handleAmountChange = name => event => {
    var value = event.target.value;
    this.setState({
      [name]: value
    });
  };
  
  handleSubmit = async event => {
  
    event.preventDefault();
  
    if (this.fileVideo && (this.fileVideo.type !== "video/mp4" && this.fileVideo.type !== "video/mov")) {
      alert(`Please pick an video with the format .mp4 or .mov.`);
      return;
    }
    if(this.state.imageChanged){
    if (this.fileImage && (this.fileImage.type !== "image/jpeg" && this.fileImage.type !== "image/png")) {
      alert(`Please pick an image with format .png or .jpeg.`);
      return;
    }}
  
    if (this.fileVideo && this.fileVideo.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`The size of your video has to be smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    this.setState({ isLoading: true });
  
    try{

      // Add new item to database
      const { clipThisId, email, maxViews, views, actionData, actionType, actionName, displayName, premium, active, qrposxpercentage, qrposypercentage, description, createdAt, edit } = this.state;
      const qrposx = qrposxpercentage;
      const qrposy = qrposypercentage;

      // File Upload
      const { bucket, region } = this.state;
      const visibility = 'private';
      const selectedFile = this.fileImage;
      const selectedVideo = this.fileVideo;
      const { identityId } = await Auth.currentCredentials();
      const userId = identityId;
      let updatedAt = {};
      let now = Date.now().toString();
      updatedAt[now] = this.state.updatedAtList;
      updatedAt = JSON.stringify(updatedAt);

      let file;
      let videoFile;

      if (selectedFile) {
        const { name: fileName, type: mimeType } = selectedFile;
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
        const key = `${visibility}/${identityId}/${clipThisId}${'.'}${extension}`;
        
        file = {
          bucket,
          key,
          region,
          mimeType,
          localUri: selectedFile
        };
      }

      if (selectedVideo) {
        const { name: fileName, type: mimeType } = selectedVideo;
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
        const key = `${visibility}/${identityId}/${clipThisId}${'.'}${extension}`;
        
        videoFile = {
          bucket,
          key,
          region,
          mimeType,
          localUri: selectedVideo
        };
      };
      
      const client = new AWSAppSyncClient({
        url: awsmobile.aws_appsync_graphqlEndpoint,
        region: awsmobile.aws_appsync_region,
        auth: {
          type: awsmobile.aws_appsync_authenticationType,
          apiKey: awsmobile.aws_appsync_apiKey,
        },
        complexObjectsCredentials: () => Auth.currentCredentials(),
      });

      const result = await client.mutate({
        mutation: gql(mutations.updateClipThisItem),
        variables: {
          input: {
            clipThisId,
            userId,
            email,
            maxViews,
            views,
            actionData,
            actionType,
            actionName,
            displayName,
            premium,
            active,
            qrposx,
            qrposy,
            file: file,
            videoFile: videoFile,
            description: description,
            createdAt: createdAt,
            updatedAt: updatedAt,
            edit
          }
        }
      });
      this.props.history.push("/pages/listitems");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }
    
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
      <div className={classes.spinner}>
        <ClipLoader
          className={classes.spinner}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={this.state.isLoading}
        /> </div>
        <div className={classes.container}>
        <Card>
        <CardBody>
        <GridContainer justify="center">
          <GridItem xs={12} sm={11}>
            <FormGroup>
              <Player  
                src={this.state.videoFile}
                fluid={false}
                height={250}
                poster={this.state.videoPoster}/>
            </FormGroup>
          </GridItem>
          <GridItem xs={5} sm={5}></GridItem>
          <GridItem xs={2} sm={2}>
            <input
              name="isGoing"
              onChange={this.handleVideoFileChange}
              type="file"
              className={classes.customFileInputInvisible}
              id="videoInput"
            />
            <label htmlFor="videoInput" className={classes.customFileInputStyle}>Choose Video</label>      
          </GridItem>
          <GridItem xs={5} sm={5}></GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={5} sm={5}></GridItem>
          </GridContainer>
          <GridContainer justify="center">
          <GridItem xs={12} sm={11}>
            <FormGroup>
              <Image style={{width: this.state.imageWidth, heigth: this.state.imageHeight}} className={"Image"} src={this.state.imageFile}/>
                <Rnd 
                  position={{x:this.state.qrposx, y: this.state.qrposy}}
                  size={{width:this.state.qrSize, height: this.state.qrSize}}
                  bounds={".Image"} 
                  onDragStop={(e, d) => { 
                    this.setState({
                      qrposx: d.x,
                      qrposy: d.y,
                      qrposxpercentage: 100*(d.x / (this.state.imageWidth-this.state.qrSize)), // Relative width of image
                      qrposypercentage: 100*(d.y / (this.state.imageHeight-this.state.qrSize))
                    })
                    console.log("x, y ", this.state.qrposxpercentage, " ", this.state.qrposypercentage)
                    }}>
                  <img style={{height:this.state.qrSize, width:this.state.qrSize}} src={qrcode} alt="QR Code Position"  title="QR Code Position"  className={classes.dragme}/>
                </Rnd>
              </FormGroup> 
              </GridItem>     
              <GridItem xs={5} sm={5}></GridItem>
              <GridItem xs={2} sm={2}>     
            <input
              onChange={this.handleImageFileChange}
              type="file"
              className={classes.customFileInputInvisible}
              id="imageInput"
            />
            <label htmlFor="imageInput" className={classes.customFileInputStyle}>Choose Image</label> 
          </GridItem>
          <GridItem xs={5} sm={5}></GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={6}><h4 className={classes.infoText}>Displayed name of item</h4></GridItem>
          <GridItem xs ={6}>
            <BasicCustomInput
              labelText={this.state.displayName}
              id="displayname"
              formControlProps={{
                fullWidth: true
              }}
              onChange={this.handleChange("displayName")}
              value={this.state.displayName}
            />
          </GridItem>
          <GridItem xs={6}><h4 className={classes.infoText}>Email adress to send confirmation email</h4></GridItem>
          <GridItem xs={6}>
        <BasicCustomInput
          labelText={this.state.email}
          id="email"
          formControlProps={{
            fullWidth: true
          }}
          onChange={this.handleChange("email")}
          value={this.state.email}
        />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Name of Action Button in App</h4>
          <p>For example: Visit Website / Find out more etc.</p>
        </GridItem>
        <GridItem xs={6}>
          <BasicCustomInput
            labelText={this.state.actionName}
            id="actionName"
            formControlProps={{
              fullWidth: true
            }}
            onChange={this.handleChange("actionName")}
            value={this.state.actionName}
          />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Link for "Visit Website" Button in the App</h4>
        </GridItem>
          <GridItem xs={6}>
        <BasicCustomInput
          labelText={this.state.actionData}
          id="actionData"
          formControlProps={{
            fullWidth: true
          }}
          onChange={this.handleChange("actionData")}
          value={this.state.actionData}
        />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Maximal views of this item</h4>
        </GridItem>
        <GridItem xs ={6}>
          <FormControl
            type="number"
            value={this.state.maxViews}
            onChange={this.handleAmountChange("maxViews")}
            placeholder=""
          />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Premium content</h4>
        </GridItem>
        <GridItem xs ={6}>
          <FormControl
            checked={this.state.premium}
            type="checkbox"
            value={this.state.premium}
            onChange={this.handleCheckboxChoice("premium")}
            placeholder=""
          />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Content active</h4>
        </GridItem>
        <GridItem xs ={6}>
          <FormControl
            checked={this.state.active}
            type="checkbox"
            value={this.state.active}
            onChange={this.handleCheckboxChoice("active")}
            placeholder=""
          />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Activation date</h4>
        </GridItem>
        <GridItem xs ={6}>
          <FormControl
            type="checkbox"
            value={this.state.numberOfCards}
            onChange={this.handleAmountChange}
            placeholder=""
          />
        </GridItem>
        <GridItem xs={6}>
          <h4 className={classes.infoText}>Deactivation date</h4>
        </GridItem>
        <GridItem xs ={6}>
          <FormControl
                type="checkbox"
                value={this.state.numberOfCards}
                onChange={this.handleAmountChange}
                placeholder=""
              />
        </GridItem>
        <GridItem>
          <Button 
            round 
            color="rose" 
            onClick={this.handleSubmit}
            //disabled={!this.validateForm}
            >
            Change item
          </Button>
        </GridItem>
      </GridContainer>

        </CardBody>
        </Card>
      </div>
      </div>
    );
  }
}

EditItem.propTypes = {
    classes: PropTypes.object.isRequired
  };
  
export default withStyles(privateUploadPageStyle)(EditItem)