import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import FileCopy from "@material-ui/icons/FileCopy";
import Store from "@material-ui/icons/Store";
import Info from "@material-ui/icons/Info";
//import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
//import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import Timeline from "@material-ui/icons/Timeline";
import ForwardIcon from "@material-ui/icons/Forward";
import WallpaperIcon from "@material-ui/icons/Wallpaper";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import Table from "../../../components/Table/Table.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
//import Danger from "../../components/Typography/Danger.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "../../../components/Card/CardHeader.jsx";
import CardIcon from "../../../components/Card/CardIcon.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import CardFooter from "../../../components/Card/CardFooter.jsx";

// AWS App Sync

import {
  dailySalesChart,
  dailySubChart,
  emailsSubscriptionChart,
  completedTasksChart,
  pieChart
} from "../../../variables/charts";

import dashboardStyle from "../../../assets/views/dashboardStyle";

import Sidebar from "../../../components/Sidebar/Sidebar.jsx";


class Dashboard extends React.Component {

  state = {
    isLoading: true,
    displayName: this.props.location.state.displayName,
    analytics: this.props.location.state.analytics,

    viewsTotal: 0,
    viewsLast30Days: 0,  
    usersTotal: 0,
    usersLast30Days: 0,
    averageActions: 0,
    averageViewsPerUser: 0,
    stdViewsPerUser: 0,

    numberDays: 7,
    dailyViews7Chart: {labels: ["M", "T", "W", "T", "F", "S", "S"], series:[[1,2,3,4,5,6,7]]},
    dailyAction7Chart: null,
    dailyViews30Chart: {labels: ["M", "T", "W", "T", "F", "S", "S"], series:[[1,2,3,4,5,6,7]]},
    dailyAction30Chart: null,
    dailyViews90Chart: {labels: ["M", "T", "W", "T", "F", "S", "S"], series:[[1,2,3,4,5,6,7]]},
    dailyAction90Chart: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    
    this.fillAnalyticsdashboard();
  }

  onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
  }

  countOccurences(arr_dates, arr_actions, value){
    var countDates = 0;
    var countActions = 0;
    console.log("Value ", value)
    for (var i = 0; i < arr_dates.length; i++) {
      if (arr_dates[i].getDate() === value.getDate() && arr_dates[i].getMonth() ===value.getMonth()) {
        countDates++;
        if(arr_actions[i]){
          countActions++;
        }
      }
    }
    var list = [countDates, countActions]
    return list
  }

  fillAnalyticsdashboard(){

    let analytics = JSON.parse(this.state.analytics)

    var dates = [];
    var users = [];
    var actions = [];

    for(var i=0; i<analytics.length; i++){
      let data = analytics[i]
      dates.push(new Date(data[1]))
      users.push(data[0])
      actions.push(data[2])
    };

    var fromDate = new Date();
    fromDate.setDate(fromDate.getDate()-30);

    const lastthirtydays = dates.filter(a=> a.getTime() >= fromDate.getTime());
    const viewsLast30Days = lastthirtydays.length;
    const viewsTotal = dates.length
    const numberActions = actions.filter(v => v).length;
    const numberUsers = users.filter( this.onlyUnique ).length;

    var labels = [];
    var series = [];
    var actionSeries = [];
    var labels30 = []
    var series30 = [];
    var actionSeries30 = [];
    var labels90 = [];
    var series90 = [];
    var actionSeries90 = [];

    for(var i=7; i>0; i--){
      var date = new Date();
      date.setDate(date.getDate() - i +1);
      var dd = date.getDate();
      var mm = date.getMonth()+1; // January is 0!
      const day = dd+"."+mm;
      labels.push(day);

      var counts = this.countOccurences(dates, actions, date)
      series.push(counts[0]);
      actionSeries.push(counts[1]);
    }

    for(var i=30; i>0; i--){
      var date = new Date();
      date.setDate(date.getDate() - i +1);
      var dd = date.getDate();
      var mm = date.getMonth()+1; // January is 0!
      const day = dd+"."+mm;
      labels30.push(day);

      var counts = this.countOccurences(dates, actions, date)
      series30.push(counts[0]);
      actionSeries30.push(counts[1]);
    }

    for(var i=90; i>0; i--){
      var date = new Date();
      date.setDate(date.getDate() - i +1);
      var dd = date.getDate();
      var mm = date.getMonth()+1; // January is 0!
      const day = dd+"."+mm;
      labels90.push(day);

      var counts = this.countOccurences(dates, actions, date)
      console.log("Counts ", counts)
      series90.push(counts[0]);
      actionSeries90.push(counts[1]);
    }
    
    this.setState({
      isLoading: false,
      viewsTotal: viewsTotal,
      viewsLast30Days: viewsLast30Days,
      usersTotal: numberUsers,
      averageActions: Math.round(100*(numberActions)/ (numberUsers+1))/100,
      averageViewsPerUser: Math.round(100*(viewsTotal) / (numberUsers+1))/100,
      dailyViews7Chart: {labels: labels, series:[series]},
      dailyAction7Chart: {labels: labels, series:[actionSeries]},
      dailyViews30Chart: {labels: labels30, series:[series30]},
      dailyAction30Chart: {labels: labels30, series:[actionSeries30]},
      dailyViews90Chart: {labels: labels90, series:[series90]},
      dailyAction90Chart: {labels: labels90, series:[actionSeries90]}
    })
  }
  
  render() {
    const { classes } = this.props;

    return (
      <div style={{backgroundColor:"#333333"}}>
      <div className={classes.content}>
        <GridContainer>
          <Sidebar/>
        </GridContainer>
       <div className={classes.container}>
        <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}>{this.state.displayName}</h2>
            </GridItem>
          </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <WallpaperIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Abrufe gesamter Zeitraum</p>
                <h3 className={classes.cardTitle}>
                  {this.state.viewsTotal} <small> von {this.state.usersTotal} Usern</small>
                </h3>
              </CardHeader>
              <CardFooter stats>
              <div className={classes.stats}>
                  <DateRange />
                  Gesamter Zeitraum
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <WallpaperIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Abrufe in den letzten 30 Tagen</p>
                <h3 className={classes.cardTitle}>{this.state.viewsLast30Days}</h3>
              </CardHeader>
              <CardFooter stats>
              <div className={classes.stats}>
                  <DateRange />
                  Letzte 30 Tage
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ForwardIcon />
                </CardIcon>
                <p className={classes.cardCategory}>Weiterleitungsrate</p>
                <h3 className={classes.cardTitle}>{this.state.averageActions}<small>(Klicks auf Link pro User)</small></h3>
              </CardHeader>
              <CardFooter stats>
              <div className={classes.stats}>
                  <DateRange />
                  Letzte 30 Tage
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <ForwardIcon />
                  {/*<FontAwesomeIcon icon={faTwitter} />*/}
                </CardIcon>
                <p className={classes.cardCategory}>Durchschnittliche Abrufe pro User</p>
                <h3 className={classes.cardTitle}>{this.state.averageViewsPerUser}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="lightgray" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={this.state.dailyViews7Chart}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                  className={classes.graphStyle}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Tägliche Views der letzen 7 Tage</h4>
                {/*<p className={classes.cardCategory}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                  </span>{" "}
                  Steigerung in heutigen Views.
                </p>*/}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
    
          <GridItem xs={12} sm={12} md={6}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="lightgray" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={this.state.dailyAction7Chart}
                  type="Line"
                  options={dailySubChart.options}
                  listener={dailySalesChart.animation}
                  className={classes.graphStyle}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Tägliche Weiterleitungen der letzen 7 Tage</h4>
                {/*<p className={classes.cardCategory}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 35%
                  </span>{" "}
                  Steigerung in heutigen Weiterleitungen.
                </p>*/}
              </CardBody>
              <CardFooter chart>
              <div className={classes.stats}>
                  <AccessTime /> updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="lightgray" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={this.state.dailyViews30Chart}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                  className={classes.graphStyle}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Tägliche Views der letzen 30 Tage</h4>
                {/*<p className={classes.cardCategory}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                  </span>{" "}
                  Steigerung in heutigen Views.
                </p>*/}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
    
          <GridItem xs={12} sm={12} md={6}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="lightgray" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={this.state.dailyAction30Chart}
                  type="Line"
                  options={dailySubChart.options}
                  listener={dailySalesChart.animation}
                  className={classes.graphStyle}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Tägliche Weiterleitungen der letzen 30 Tage</h4>
                {/*<p className={classes.cardCategory}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 35%
                  </span>{" "}
                  Steigerung in heutigen Weiterleitungen.
                </p>*/}
              </CardBody>
              <CardFooter chart>
              <div className={classes.stats}>
                  <AccessTime /> updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="lightgray" className={classes.cardHeaderHover}>
                <ChartistGraph
                  data={this.state.dailyViews90Chart}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                  className={classes.graphStyle}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Tägliche Views der letzen 90 Tage</h4>
                {/*<p className={classes.cardCategory}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                  </span>{" "}
                  Steigerung in heutigen Views.
                </p>*/}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
    
          <GridItem xs={12} sm={12} md={12}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="lightgray" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={this.state.dailyAction90Chart}
                  type="Line"
                  options={dailySubChart.options}
                  listener={dailySalesChart.animation}
                  className={classes.graphStyle}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Tägliche Weiterleitungen der letzen 90 Tage</h4>
                {/*<p className={classes.cardCategory}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 35%
                  </span>{" "}
                  Steigerung in heutigen Weiterleitungen.
                </p>*/}
              </CardBody>
              <CardFooter chart>
              <div className={classes.stats}>
                  <AccessTime /> updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>

      </div>
      </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};
 
export default withStyles(dashboardStyle)(Dashboard)

