// ##############################
// // // LoginPage view styles
// #############################

import {
    container,
    largeScreenSideBar,
    mediumScreenSideBar,
    cardTitle,
    sideBarWidth
  } from "../views/material-dashboard-pro-react.jsx";
  
  const listItemsPageStyle = theme => ({
    container: {
      ...container,
      [theme.breakpoints.up("1224")]: {
        marginRight: `calc((100vw - ${mediumScreenSideBar}px)/2)`, // align content center on remaining of page
        width: "970px"
      },
      [theme.breakpoints.up("1390")]: {
        marginRight: `calc((100vw - ${largeScreenSideBar}px)/2)`, // align content center on remaining of page
        width: "1170px"
      },
    },
    listItemContainer: {
      //position: "flex",
      // display: "webkit-box",
      // display: "ms-flexbox",
      //flexDirection: "column",
      backgroundColor: "#fff",
      padding: "5px 7px"
    },
    content: {
      backgroundColor: "#eee",
      paddingTop: "18vh",
      paddingBottom: "9vh",
      minHeight: "calc(100vh - 80px)",
      position: "relative",
      zIndex: "4",
      [theme.breakpoints.down("1224")]: {
        paddingTop: "0"
      },
    },
    spinner: {
      display: "block",
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: "5",
      margin: "0 auto"
    },
    table:{
      textAlign: "center"
    },
    
    // cardTitle: {
    //   ...cardTitle,
    //   textAlign: "center"
    // },
    // cardLIst: {
    //   borderRadius: "6px",
    //   boxShadow:
    //     "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    //   marginBottom: "100px",
    //   padding: "40px 0px",
    //   marginTop: "15vh"
    // },
    // center: {
    //   textAlign: "center"
    // },
    // right: {
    //   textAlign: "right"
    // },
    // left: {
    //   textAlign: "left"
    // },
    // form: {
    //   padding: "0 20px",
    //   position: "relative"
    // },
    // socialTitle: {
    //   fontSize: "18px"
    // },
    // inputAdornment: {
    //   marginRight: "18px",
    //   position: "relative"
    // },
    // inputAdornmentIcon: {
    //   color: "#555"
    // },
    // customFormControlClasses: {
    //   margin: "0 12px"
    // },
    // checkboxLabelControl: {
    //   margin: "0"
    // },
    // checkboxLabel: {
    //   marginLeft: "6px",
    //   color: "rgba(0, 0, 0, 0.26)"
    // }
  });
  
  export default listItemsPageStyle;
  