import React from "react";
import PropTypes from "prop-types";
import { Auth } from "aws-amplify";
import MediaQuery from 'react-responsive';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl';

// @material-ui/icons
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";
import Check from "@material-ui/icons/CheckBox";
import UncheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import KeyIcon from "@material-ui/icons/VpnKey";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import FilterIcon from "@material-ui/icons/FilterCenterFocus";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// core components
//import LoaderButton from "../../components/LoaderButton"
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import InfoArea from "../../../components/InfoArea/InfoArea.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Button from "../../../components/CustomButtons/Button.jsx"

// Import Style
import registerPageStyle from "../../../assets/views/registerPageStyle";



class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      isLoading: false,
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null,
      usernameMinLength: 0,
      passwordMinLength: 7,
      emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      showPassword: false,
      showConfirmPassword: false
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.nextPath = this.nextPath.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }

  validateForm() {
    return (
      this.state.checked.length > 0
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  }

  handleFbLogin = () => {
    this.props.userHasAuthenticated(true);
  };  

  handleSubmit = async event => {
    event.preventDefault();
    if(this.state.username ===0){
      alert("Bitte geben Sie einen Namen ein.");
      return
    }

    if(this.state.password.length < this.state.passwordMinLength){
      alert("Passwort muss mindestens 8 Ziffern haben.");
      return
    }
    if (this.state.password !== this.state.confirmPassword){
      alert("Passwörter stimmen nicht überein.");
      return
    }
    if(!this.state.emailRegex.test(this.state.email)){
      alert("Geben Sie eine gültige Email Adresse ein.");
      return
    }
    if(!(/\d/.test(this.state.password))){
      alert("Passwort muss eine Zahl beinhalten.");
      return
    }
    if(this.state.password === this.state.password.toLowerCase()){
      alert("Passwort muss Groß und Kleinbuchstaben enthalten.");
      return
    }
    
    this.setState({ isLoading: true });
    let error = false;
    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password
      }).then(
        data => console.log(data)).catch(
        err => {
          if(err.code === "UsernameExistsException"){
            alert("Email ist bereits vorhanden.");
            error = true;
          };
      });

      if(error!==true){
      this.setState({
        newUser
      });}

    } catch (e) {
      alert("Mistake");
      alert(e.message);
    }
  
    this.setState({ isLoading: false });
  }
  
  handleConfirmationSubmit = async event => {
    event.preventDefault();
  
    this.setState({ isLoading: true });
        
    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      await Auth.signIn(this.state.email, this.state.password);
  
      this.props.userHasAuthenticated(true, this.state.email);
      this.props.history.push("/pages/listitems");
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }

  handlePasswordVisibility = name => {
    if(name === 'showConfirmPassword'){
      this.setState({
        showConfirmPassword: !this.state.showConfirmPassword
      });
    } else {
      this.setState({
        showPassword: !this.state.showPassword
      });
    }
  }

  nextPath(path) {

    this.props.history.push({
      pathname: path,
      });
  }

  renderConfirmationForm() {
    const { classes } = this.props;
    return (
      <div>
        <MediaQuery minWidth={1224}>
          <div className={classes.content}>
            <div className={classes.container}>
              <form onSubmit={this.handleConfirmationSubmit}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={10}>
                    <Card className={classes.cardSignup}>
                      <h2 className={classes.cardTitle}>Email Bestätigungscode</h2>
                      <CardBody>
                        <h4 className={classes.cardTitle}> Wir haben Ihnen gerade auf <span style={{fontWeight:"bold"}}>{this.state.email}</span> einen Bestätigungscode gesendet.</h4>
                        <h4 className={classes.cardTitle}>Bitte geben Sie diesen im unteren Feld ein.</h4>
                        <FormControl className={classes.margin}>
                          <CustomInput
                            autoFocus
                            value={this.state.confirmationCode}
                            onChange={this.handleChange('confirmationCode')}
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses
                            }}
                            inputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <KeyIcon className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                              ),
                              placeholder: "Bestätigungscode"
                                  }}
                                />
                        </FormControl>
                        <br/>
                        <br/>
                        <Button 
                          round 
                          color="primary" 
                          onClick={this.handleConfirmationSubmit}
                          disabled={!this.validateConfirmationForm()}>
                            Bestätigen</Button>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </form>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <div className={classes.contentMobile}>
            <div className={classes.container}>
              <form onSubmit={this.handleConfirmationSubmit}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={10}>
                      <h2 className={classes.cardTitle}>Email Bestätigungscode</h2>
                        <h5 className={classes.cardTitle}> Wir haben Ihnen gerade auf <span style={{fontWeight:"bold"}}>{this.state.email}</span> einen Bestätigungscode gesendet.</h5>
                        <h5 className={classes.cardTitle}>Bitte geben Sie diesen im unteren Feld ein.</h5>
                        <FormControl className={classes.confirmationInputForm}>
                          <CustomInput
                            autoFocus
                            value={this.state.confirmationCode}
                            onChange={this.handleChange('confirmationCode')}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <KeyIcon className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                              ),
                              placeholder: "Bestätigungscode"
                                  }}
                                />
                        </FormControl>
                        <br/>
                        <br/>
                        <Button 
                          fullWidth
                          round 
                          color="primary" 
                          onClick={this.handleConfirmationSubmit}
                          disabled={!this.validateConfirmationForm()}>
                            Bestätigen</Button>
                  </GridItem>
                </GridContainer>
              </form>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }

  renderForm() {
    const { classes } = this.props;
    return (
      <div>
        <MediaQuery minWidth={1224}>
          <div className={classes.content}>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={10}>
                  <Card className={classes.cardSignup}>
                    <h2 className={classes.cardTitle}>Registrierung</h2>
                    <CardBody>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={5}>
                          <InfoArea
                            title="Ihre persönliche Augmented Reality"
                            description="Erstellen Sie mit nur ein paar Klicks ihre eigenen Augemnted Reality Inhalte."
                            icon={FilterIcon}
                            iconColor="primary"
                          />
                          <InfoArea
                            title="Erwecken Sie Bilder zum Leben"
                            description="Mit CLIPTHIS können Sie auf alle Bilder ihrer Wahl ein Video abspielen. Probieren Sie es aus!"
                            icon={WallpaperIcon}
                            iconColor="primary"
                          />
                          <InfoArea
                            title="Überraschen Sie ihre Familie und Freude"
                            description="Seien Sie die erste Person in ihrem Umfeld und bereiten Sie allen um Sie herum eine Freude."
                            icon={Group}
                            iconColor="primary"
                          />
                        </GridItem>
                        <GridItem xs={12} sm={8} md={5}>
                  
                          <hr />
                          <form className={classes.form} onSubmit={this.handleSubmit}>
                            <CustomInput
                              autoFocus
                              value={this.state.username}
                              onChange={this.handleChange('username')}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Face className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                                placeholder: "Vorname Name..."
                              }}
                            />
                            <CustomInput
                              value={this.state.email}
                              onChange={this.handleChange('email')}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Email className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                                placeholder: "Email"
                              }}
                            />
                            <CustomInput
                              value={this.state.password}
                              onChange={this.handleChange('password')}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <LockOutline
                                      className={classes.inputAdornmentIcon}
                                    />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <inputAdornment position="end">
                                    <IconButton
                                      aria-label="Toggle password visibility"
                                      onClick={() => this.handlePasswordVisibility('showPassword')}
                                    >
                                      {this.state.showPassword ?  <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </inputAdornment>
                                ),
                                placeholder: "Passwort",
                                type: this.state.showPassword ? 'text' : 'password'
                              }}
                            />
                            <CustomInput
                              value={this.state.confirmPassword}
                              onChange={this.handleChange('confirmPassword')}
                              formControlProps={{
                                fullWidth: true,
                                className: classes.customFormControlClasses
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <LockOutline
                                      className={classes.inputAdornmentIcon}
                                    />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <inputAdornment position="end">
                                    <IconButton
                                      aria-label="Toggle password visibility"
                                      onClick={() => this.handlePasswordVisibility('showConfirmPassword')}
                                    >
                                      {this.state.showConfirmPassword ?  <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </inputAdornment>
                                ),
                                placeholder: "Passwort bestätigen",
                                type: this.state.showConfirmPassword ? 'text' : 'password'
                              }}
                            />
                            <FormControlLabel
                              classes={{
                                root: classes.checkboxLabelControl,
                                label: classes.checkboxLabel
                              }}
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={() => this.handleToggle(1)}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={<UncheckedIcon className={classes.uncheckedIcon} />}
                                  classes={{
                                    checked: classes.checked
                                  }}
                                />
                              }
                              label={
                                <span>
                                  Ich akzeptiere hiermit die {" "}
                                  <a href="agb" target="_blank">AGBs</a>.
                                </span>
                              }
                            />
                            <p>Das Passwort muss aus Gross/Kleinbuchstaben bestehen und eine Zahl enthalten.</p>
                            <Button 
                              round 
                              color="primary" 
                              onClick={this.handleSubmit}
                              disabled={!this.validateForm()}>
                                Registrieren</Button>
                          </form>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <div className={classes.contentMobile}>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12}>
                    <h2 className={classes.cardTitle}>Registrierung</h2>
                      <GridContainer justify="center">
                        <GridItem xs={12}>
                          <InfoArea
                            title="Ihre persönliche Augmented Reality"
                            description="Erstellen Sie mit nur ein paar Klicks ihre eigenen Augemnted Reality Inhalte."
                            icon={FilterIcon}
                            iconColor="primary"
                          />
                          <InfoArea
                            title="Erwecken Sie Bilder zum Leben"
                            description="Mit CLIPTHIS können Sie auf alle Bilder ihrer Wahl ein Video abspielen. Probieren Sie es aus!"
                            icon={WallpaperIcon}
                            iconColor="primary"
                          />
                          <InfoArea
                            title="Überraschen Sie ihre Familie und Freude"
                            description="Seien Sie die erste Person in ihrem Umfeld und bereiten Sie allen um Sie herum eine Freude."
                            icon={Group}
                            iconColor="primary"
                          />
                        </GridItem>
                        <GridItem xs={12}>
                          <br/>
                          
                          <hr />
                          <form className={classes.form} onSubmit={this.handleSubmit}>
                            <CustomInput
                              autoFocus
                              value={this.state.username}
                              onChange={this.handleChange('username')}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Face className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                                placeholder: "Vorname Name"
                              }}
                            />
                            <CustomInput
                              value={this.state.email}
                              onChange={this.handleChange('email')}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <Email className={classes.inputAdornmentIcon} />
                                  </InputAdornment>
                                ),
                                placeholder: "Email"
                              }}
                            />
                            <CustomInput
                              value={this.state.password}
                              onChange={this.handleChange('password')}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <LockOutline
                                      className={classes.inputAdornmentIcon}
                                    />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <inputAdornment position="end">
                                    <IconButton
                                      aria-label="Toggle password visibility"
                                      onClick={() => this.handlePasswordVisibility('showPassword')}
                                    >
                                      {this.state.showPassword ?  <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </inputAdornment>
                                ),
                                placeholder: "Passwort...",
                                type: this.state.showPassword ? 'text' : 'password'
                              }}
                            />
                            <CustomInput
                              value={this.state.confirmPassword}
                              onChange={this.handleChange('confirmPassword')}
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className={classes.inputAdornment}
                                  >
                                    <LockOutline
                                      className={classes.inputAdornmentIcon}
                                    />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <inputAdornment position="end">
                                    <IconButton
                                      aria-label="Toggle password visibility"
                                      onClick={() => this.handlePasswordVisibility('showConfirmPassword')}
                                    >
                                      {this.state.showConfirmPassword ?  <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </inputAdornment>
                                ),
                                placeholder: "Passwort bestätigen...",
                                type: this.state.showConfirmPassword ? 'text' : 'password'
                              }}
                            />
                            <FormControlLabel
                              classes={{
                                root: classes.checkboxLabelControl,
                                label: classes.checkboxLabel
                              }}
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={() => this.handleToggle(1)}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={<UncheckedIcon className={classes.uncheckedIcon} />}
                                  classes={{
                                    checked: classes.checked
                                  }}
                                />
                              }
                              label={
                                <span>
                                  Ich akzeptiere hiermit die {" "}
                                  <a href="http://www.clip-this.com">AGBs</a>.
                                </span>
                              }
                            />
                            <p>Das Passwort muss aus Gross/Kleinbuchstaben bestehen und eine Zahl enthalten.</p>
                            <Button 
                              fullWidth
                              round 
                              color="primary" 
                              onClick={this.handleSubmit}
                              disabled={!this.validateForm()}>
                                Registrieren</Button>
                          </form>
                        </GridItem>
                      </GridContainer>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(RegisterPage);
