// eslint-disable
// this is an auto generated file. This will be overwritten

export const createClipThisItem = `mutation CreateClipThisItem($input: CreateClipThisItemInput!) {
  createClipThisItem(input: $input) {
    clipThisId
    userId
    email
    maxViews
    views
    analytics
    actionData
    actionType
    actionName
    displayName
    premium
    active
    qrposx
    qrposy
    file {
      bucket
      region
      key
    }
    videoFile {
      bucket
      region
      key
    }
    description
    createdAt
    updatedAt
    imageFileLocation
    videoFileLocation
    campaign
    webapp
  }
}
`;
export const updateClipThisItem = `mutation UpdateClipThisItem($input: UpdateClipThisItemInput!) {
  updateClipThisItem(input: $input) {
    clipThisId
    userId
    email
    maxViews
    views
    analytics
    actionData
    actionType
    actionName
    displayName
    premium
    active
    qrposx
    qrposy
    file {
      bucket
      region
      key
    }
    videoFile {
      bucket
      region
      key
    }
    description
    createdAt
    updatedAt
    imageFileLocation
    videoFileLocation
    campaign
    webapp
  }
}
`;
export const deleteClipThisItem = `mutation DeleteClipThisItem($input: DeleteClipThisItemInput!) {
  deleteClipThisItem(input: $input) {
    clipThisId
    userId
    email
    maxViews
    views
    analytics
    actionData
    actionType
    actionName
    displayName
    premium
    active
    qrposx
    qrposy
    file {
      bucket
      region
      key
    }
    videoFile {
      bucket
      region
      key
    }
    description
    createdAt
    updatedAt
    imageFileLocation
    videoFileLocation
    campaign
    webapp
  }
}
`;
export const createClipThisCampaignsDev = `mutation CreateClipThisCampaignsDev($input: CreateClipThisCampaignsDevInput!) {
  createClipThisCampaignsDev(input: $input) {
    title
    userId
    description
    isPublic
    items
    searchKeywords
    tags
    userDisplayName
  }
}
`;
export const updateClipThisCampaignsDev = `mutation UpdateClipThisCampaignsDev($input: UpdateClipThisCampaignsDevInput!) {
  updateClipThisCampaignsDev(input: $input) {
    title
    userId
    description
    isPublic
    items
    searchKeywords
    tags
    userDisplayName
  }
}
`;
export const deleteClipThisCampaignsDev = `mutation DeleteClipThisCampaignsDev($input: DeleteClipThisCampaignsDevInput!) {
  deleteClipThisCampaignsDev(input: $input) {
    title
    userId
    description
    isPublic
    items
    searchKeywords
    tags
    userDisplayName
  }
}
`;
export const addItemToCampaign = `mutation AddItemToCampaign(
  $clipThisId: String!
  $userId: String!
  $title: String!
) {
  addItemToCampaign(clipThisId: $clipThisId, userId: $userId, title: $title) {
    clipThisId
    userId
    email
    maxViews
    views
    analytics
    actionData
    actionType
    actionName
    displayName
    premium
    active
    qrposx
    qrposy
    file {
      bucket
      region
      key
    }
    videoFile {
      bucket
      region
      key
    }
    description
    createdAt
    updatedAt
    imageFileLocation
    videoFileLocation
    campaign
    webapp
  }
}
`;
export const createClipThisEmailItem = `mutation CreateClipThisEmailItem($input: CreateClipThisEmailTextInput!) {
  createClipThisEmailItem(input: $input) {
    clipThisId
    emailText
  }
}
`;
