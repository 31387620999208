
const config = {
  s3: {
    REGION: "eu-central-1",
    BUCKET: "clipthis-helvetia-platform-upload", 
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_dhGw3vBOh",
    APP_CLIENT_ID: "abfpj291juev2ffu780127uu6",
    IDENTITY_POOL_ID: "eu-central-1:1b039330-afab-4dbf-905e-6ea6645c0cc8"
  }
};

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 50000000,
  ...config
};
    
    