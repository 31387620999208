// ##############################
// // // Dashboard View styles
// #############################

import {
  container,
  largeScreenSideBar,
  mediumScreenSideBar,
  tooltip,
  defaultFont,
  colors,
  cardTitle
} from "./material-dashboard-pro-react.jsx";

import hoverCardStyle from "../hoverCardStyle.jsx";

const dashboardStyle = theme => ({

  graphStyle: {
    '& .ct-label': { fill: 'red' },
    '& .ct-series-a .ct-point': {
        /* Colour of your points */
        stroke: 'black',
        /* Size of your points */
        strokeWidth: '5px',
        /* Make your points appear as squares */
        strokeLinecap: 'dot',
        lineWidth: '3px',
        line: 'black'
      },
    '& .ct-series-a .ct-line' :{
        /* Set the colour of this series line */
        stroke: 'black',
        /* Control the thikness of your lines */
        strokeWidth: '3px',
        /* Create a dashed line with a pattern */
      },
    '& .ct-label': {
      fontSize: '10px',
      color: 'black'
      },
    '& .ct-label.ct-label.ct-horizontal.ct-end': {
      position: 'relative',
      justifyCcontent: 'flex-end',
      textAlign: 'right',
      transformOrigin: '100% 0',
      transform: 'translate(-100%) rotate(-45deg)',
      whiteSpace: 'nowrap',
    }

    },

  ...hoverCardStyle,
  tooltip,
  content: {
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    zIndex: "4",
    backgroundColor: "#eee"
  },
  container: {
    ...container,
    [theme.breakpoints.up("1224")]: {
      marginRight: `calc((100vw - ${mediumScreenSideBar}px)/2)`, // align content center on remaining of page
      width: "970px"
    },
    [theme.breakpoints.up("1390")]: {
      marginRight: `calc((100vw - ${largeScreenSideBar}px)/2)`, // align content center on remaining of page
      width: "1170px"
    },
  },
  title: {
    ...defaultFont,
    color: "#000",
    marginTop: "13vh",
    marginBottom: "30px",
    textAlign: "center"
  },
  description: {
    fontSize: "18px",
    color: "#000",
    textAlign: "center"
  },
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  legendTitle: {
    color: colors.grayColor,
    margin: "10px 0 !important",
    display: "flex"
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center"
  },
  cardCategory: {
    color: "#999999",
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },
  cardProductDesciprion: {
    textAlign: "center",
    color: "#999999"
  },
  stats: {
    color: "#000",
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& i": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0"
  },
  successText: {
    color: colors.successColor
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px"
    }
  },
  warning: {
    color: colors.warningColor
  },
  danger: {
    color: colors.dangerColor
  },
  info: {
    color: colors.infoColor
  },
  cardFooter: {
    display: "block"
  },
  lineGraph: {
    fontSize: "10px",
    lineWidth: "2px",
    color: "#000"
  },
  primaryButtonColor: {
    color: colors.primaryButtonColor
  }
});

export default dashboardStyle;
