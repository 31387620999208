import React from "react";
import PropTypes from "prop-types";
import MediaQuery from 'react-responsive';

// aws immport
import { randomString } from "../../../libs/randomString";
//import s3Upload from "../../libs/awsLib"
import { Storage, Auth } from "aws-amplify";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {Image, FormGroup, FormControl} from "react-bootstrap";
import "../../../../node_modules/video-react/dist/video-react.css"; // import css
import { Player } from 'video-react';
import {Rnd} from "react-rnd";  // Move image libary
import "../../../containers/DragQRCode.css"  // Needed for the libary used to make the qr code dragable
import isUrl from 'is-url';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import BasicCustomInput from "../../../components/BasicCustomInput/BasicCustomInput.jsx";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button.jsx";
import Sidebar from "../../../components/Sidebar/Sidebar.jsx";
import MobileMessage from "../../../components/MobileMessage/MobileMessage.jsx";
import qrcode from '../../../assets/image/qrcode.png'
import config from "../../../config";
import TextField from '@material-ui/core/TextField';

import privateUploadPageStyle from "../../../assets/views/privateUploadPageStyle.jsx";
import { Card } from "@material-ui/core";
import { ClipLoader } from 'react-spinners';

import awsmobile from '../../../aws-exports';
import AWSAppSyncClient from "aws-appsync";
import gql from 'graphql-tag'
import * as mutations from "../../../graphql/mutations"


class TestNow extends React.Component {
  constructor(props) {
    super(props);

    this.fileVideo = null;
    this.fileImage = null;

    const html = `<p>Guten Tag,</p>
                  <p>Vielen Dank, dass Sie sich für das Thema kundenzentriertes Verhalten interessieren.</p>
                  <p>Sie finden Ausführungen zu unseren Verhaltensleitlinien, Tipps & Tricks sowie diverse Anleitungen im Helvetia Wiki unter folgendem Link:</p>
                  <div></div>
                  <p><a href="https://wiki.helvetia.group/display/PUQM/Kundenzentrierung+-+Verhaltensleitlinien">https://wiki.helvetia.group/display/PUQM/Kundenzentrierung+-+Verhaltensleitlinien</a></p>
                  <div></div>
                  <p>Bei Fragen steht Ihnen das Customer Centricity Team gerne zur Verfügung.</p>
                  <p>Freundliche Gr&#252;sse</p>
                  <div>Eliana W&#252;st</div>
                  <div>Head Customer Centricity</div>
                  <div>Business Model & Transformation</div>
                  <div>Corporate Development</div>
                  <div></br></div>
                  <div>Helvetia Versicherungen, HS St. Gallen (Hauptsitz)</div>
                  <div>Dufourstrasse 40, 9001 St.Gallen, Schweiz </div>
                  <div>T +41 58 280 10 00 , direkt  058 280 5781</div>
                  <div><a href="mailto:eliana.wuest@helvetia.ch">eliana.wuest@helvetia.ch</a></div>
                  <div style="font-weight: bold">einfach. klar. helvetia. </div>
                  `;

    const contentBlock = htmlToDraft(html);

    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    this.state = {
      // File Upload paramteres
      bucket: config.s3.BUCKET,
      region: "eu-central-1",
      level: "private",

      // General paramters
      isLoading: false,           // Showing rotating loading spinner
      imageWidth: 401,//1604,
      imageHeight: 250,//902,
      qrSize:0,
      qrposx: 0,
      qrposy: 0,
      videoWidth: 852,
      videoHeight:0,
      videoPoster: null,    // Image in video player
      videoFrameHeight:0,

      // Database entries
      videoFile: null, //props.getStore().videofile,
      imageFile: null, //props.getStore().imagefile,
      videoChanged: false,
      imageChanged: false,
      qrposxpercentage: 0,
      qrposypercentage: 0,

      email: this.props.email,
      clipThisId: randomString(7),
      userId: this.props.userName,
      views: 0,
      actionData: "www.clipthis.ch",
      actionType: "sendEmail",
      actionName: "Erfahren Sie mehr",
      displayName: "Test item",
      premium: false,
      active: true,
      datalaws: false,
      description: "No description available",
      createdAt: Date.now(),
      updatedAt: null,
      edit: false,
      maxViews: 5,
      webapp: "helvetia",

      editorState: editorState
    };

    this.nextPath = this.nextPath.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  handleNameChange = name => event => {
    if(event.target.value.length>25){
      alert("Maximale Länge ist 25 Zeichen.")
      const name = this.state.displayName;
      this.setState({
        "displayName": name.substr(0,25)
      });
    }else{
      this.setState({
        [name]: event.target.value,
      })
    }
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheckboxChoice = name => event => {
    var value = event.target.checked;
    this.setState({ [name]: value });
  };

  handleAmountChange = name => event => {
    var value = event.target.value;
    this.setState({
      [name]: value
    });
  };

  validateForm() {
      return this.state.datalaws
  }


  // Extracts width and height from video and first image of video to set as poster in player
  getVideoInfos(url, callback) {
    var video = document.createElement("VIDEO");

    video.onloadeddata = function(e) {
      var canvas = document.createElement('canvas');
      var w = video.videoWidth;
      var h = video.videoHeight;
      canvas.height = h;
      canvas.width = w;
      var ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      var img = new Image();
      img.src = canvas.toDataURL();
      callback({width: w, height: h, image: img, error: e});
    };
    video.onerror = function(e) {
      callback.call(undefined, undefined, undefined, e);
    };
    // Setting the source makes it start downloading and eventually call `onload`
    video.src = url;
  }
  
  // Called when user chooses video. Sets new video, triggers getting of width and height.
  handleVideoFileChange = event => {
    this.fileVideo = event.target.files[0];
    if (this.fileVideo.type !== "video/mp4") {
      alert(`Please pick an video with the format .mp4.`);
      return;
    }

    if (this.fileVideo && this.fileVideo.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Die Größe ihres Videos sollte ${config.MAX_ATTACHMENT_SIZE/1000000} MB nicht überschreiten.`);
      return;
    }

    let url = URL.createObjectURL(this.fileVideo);
    this.setState({
      videoFile: url,
      videoChanged: true,
      videoPoster: null,
      videoFrameHeight: 250
    });
    // callback function after video is changed
    this.getVideoInfos(url, (result) => {
      this.setState({videoWidth : result.width});
      this.setState({videoHeight : result.height});
      //this.setState({videoPoster: result.image.path});
      this.setState({videoWasChanged: true})
    });
  };

  // Extracts width and height of image
  getImageWidthHeight(url, callback) {
    var img = document.createElement("img");
    img.onload = function() {
      // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
      // The natural size is the actual image size regardless of rendering.
      // The 'normal' width/height are for the **rendered** size.
      var w  = img.naturalWidth  || img.width;
      var h = img.naturalHeight || img.height;
      callback({width: w, height: h});
    }
    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;
  };
  
  // Called when user changes new image. Triggers width, height extraction
  // Triggers quality check of image
  handleImageFileChange = event => {
    this.fileImage = event.target.files[0];
    if (this.fileImage.type !== "image/jpeg" && this.fileImage.type !== "image/png") {
      alert(`Bitte wählen sie eines der folgenden Formate: .png oder .jpeg.`);
      return;
    }
    if (this.fileImage && this.fileImage.size > 5000000) {
      alert(`Die Größe ihres Bildes sollte ${5000000/1000000} MB nicht überschreiten.`);
      return;
    }
    let url = URL.createObjectURL(this.fileImage);
    this.setState({
      imageFile: url,
      imageChanged: true,
      qrposx: 0,
      qrposy:0
    });

    // Check Image Quality
    //this.f(this.fileImage).then(alert('Done'))

    // Callback function after Image is changed
    this.getImageWidthHeight(url, (result) => {
      var width = 0
      var height = 0
      var qrSize = 0
      // Height > Width
      if(result.width <= result.height){
        // Check if height is bigger than screen
        if(result.height>1200){
          height = 1200
          width = height / result.height * result.width
          }else{
            width = result.width
            height = result.height
          }
        qrSize = 0.2*width;
        }
      // Width > Height
      else{
        // Check if width is bigger than screen
        if(result.width>600){
          width = 600
          height = width / result.width * result.height
          }
        else{
          width = result.width
          height = result.height
          }
        qrSize = 0.2*height;
        }
      this.setState({
        qrSize: qrSize,
        imageWidth : width,
        imageHeight: height,
      });
    });
  };

  nextPath(path) {
    this.props.history.push({
    pathname: path
    });
  }

  handleSubmit = async ({ token, error }) => {

    const valid = this.validateForm();
    if(!valid){return}

    var aspectRatioImage = Math.round(this.state.imageWidth / this.state.imageHeight * 10) / 10
    let tollerance = 0.2;
    let aspectRatioVideo = Math.round(this.state.videoWidth / this.state.videoHeight * 10) / 10;
    if(aspectRatioImage - aspectRatioVideo>tollerance || aspectRatioVideo - aspectRatioImage>tollerance){
      
      alert("Fehler: Seitenverhältnisse von Bild und Video stimmen nicht überein.");
      return;
    }

    // Activate loading spinner
    this.setState({ isLoading: true });
  
    try { 
      
      //// Add new item to database
      // Get item details
      let { clipThisId, email, views, actionData, actionType, actionName,
            displayName, premium, active, qrposxpercentage, qrposypercentage,
            description, createdAt, edit, maxViews, webapp } = this.state;
      const qrposx = qrposxpercentage;
      const qrposy = qrposypercentage;
      const analytics = JSON.stringify({});

      let now = Date.now();
      const changeList = ["actionData", "actionName", "actionType", "active", "displayName", "file", "videoFile", "description"];
      let updatedAt = {};
      updatedAt["file"] = now;
      updatedAt["videoFile"] = now;
      updatedAt = JSON.stringify(updatedAt);
    
      // Logic for image and video File location
      let imageFileLocation = {};
      let videoFileLocation = {};
      imageFileLocation["region"] = "eu-central-1";
      videoFileLocation["region"] = "eu-central-1";
      videoFileLocation["bucket"] = "clipthis-helvetia-premium-prod";

      if(premium){
        imageFileLocation["bucket"] = "clipthis-helvetia-premium-prod"
      }
      else{
        // For prodl
        imageFileLocation["bucket"] = "clipthis-helvetia-qr-prod"
        // For dev
        //imageFileLocation["bucket"] = "clipthis-images-with-qr"
      }

      // File Upload
      const { bucket, region } = this.state;
      const visibility = 'private';
      const selectedFile = this.fileImage;
      const selectedVideo = this.fileVideo;
      const { identityId } = await Auth.currentCredentials();
      const userId = identityId;

      let file;
      let videoFile;

      if (selectedFile) {
        const { name: fileName, type: mimeType } = selectedFile;
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
        const key = `${visibility}/${identityId}/${clipThisId}${'.'}${extension}`;
        if(premium){
          imageFileLocation["key"] = `${clipThisId}${'.jpg'}`;
        }else{
          imageFileLocation["key"] = `${'qr'}/${clipThisId}${'.jpg'}`;
        }
        file = {
          bucket,
          key,
          region,
          mimeType,
          localUri: selectedFile
        };
      }

      if (selectedVideo) {
        const { name: fileName, type: mimeType } = selectedVideo;
        const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
        const key = `${visibility}/${identityId}/${clipThisId}${'.'}${extension}`;
        videoFileLocation["key"] = `${clipThisId}${'.'}${extension}`;
        videoFile = {
          bucket,
          key,
          region,
          mimeType,
          localUri: selectedVideo
        };
      };
      
      const client = new AWSAppSyncClient({
        url: awsmobile.aws_appsync_graphqlEndpoint,
        region: awsmobile.aws_appsync_region,
        auth: {
          type: awsmobile.aws_appsync_authenticationType,
          apiKey: awsmobile.aws_appsync_apiKey,
        },
        complexObjectsCredentials: () => Auth.currentCredentials(),
      });
 
      let key = key;
      if(description===""){
        description="No description available";
      }
      if(actionType!=="sendEmail"){
        if(actionData===""){
          actionData=null;
          actionType=null;
        }else{
          if(actionData!==null){
            if (!isUrl(actionData)){
              this.setState({isLoading: false});
              alert("Bitte geben Sie eine gültige URL ein.");
              return
            }
          }
        }
      }
      if(displayName===""){
        this.setState({isLoading: false});
          alert("Bitte geben Sie einen Namen an.");
          return
      }
      if(email===""){
        this.setState({isLoading: false});
          alert("Bitte geben Sie eine Email Adresse an.");
          return
      }
      const videoResult = await Storage.put(clipThisId+".mp4", selectedVideo, {
        level: 'private',
        contentType: 'video/mp4'
        })
        .then (result => console.log(""))
        .catch(err => alert("Video konnte nicht hochgeladen werden"));

      const result = await client.mutate({
        mutation: gql(mutations.createClipThisItem),
        variables: {
          input: {
            clipThisId,
            userId,
            email,
            maxViews: maxViews ? maxViews : 5,
            views: views ? 0 : 0,
            analytics:analytics ? JSON.stringify({}) : null,
            actionData,
            actionType,
            actionName,
            displayName,
            premium,
            active,
            qrposx,
            qrposy,
            file,
            //videoFile,
            description,
            createdAt,
            updatedAt,
            imageFileLocation: JSON.stringify(imageFileLocation),
            videoFileLocation: JSON.stringify(videoFileLocation),
            edit: edit ? false : false,
            webapp: webapp
          }
        }
      }); 
      
      const emailText = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))


      const resultEmail = await client.mutate({
        mutation: gql(mutations.createClipThisEmailItem),
        variables: {
          input: {
            clipThisId,
            emailText}}
      });


      alert("Herzlichen Glückwunsch, Ihr Inhalt wurde erstellt. Ordnen Sie ihn jetzt einer Kampagne zu.")           
      // Redirect to homepage
      this.nextPath("/pages/listCampaigns");
      // alert("Item was added successfully!");
    } catch (e) {
      alert(e);
      // Turn of spinner loader
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
      <MediaQuery minWidth={1224}>
      <div className={classes.content}>
      <GridContainer>
        <Sidebar />
      </GridContainer>
      <div className={classes.spinner}>
        <ClipLoader
          className={classes.spinner}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={this.state.isLoading}
        /> 
      </div>
      <div className={classes.container}>
        <Card>
        <CardBody>
        <GridContainer justify="center">
          <GridItem xs={12} sm={11}>
            <FormGroup >
              <Player  
                src={this.state.videoFile}
                fluid={false}
                height={this.state.videoFrameHeight}
                poster={null}/>
            </FormGroup>
          </GridItem>
          <GridItem xs={5} sm={5}></GridItem>
          <GridItem xs={2} sm={2}>
            <input
              name="isGoing"
              onChange={this.handleVideoFileChange}
              type="file"
              className={classes.customFileInputInvisible}
              id="videoInput"
            />
            <label htmlFor="videoInput" className={classes.customFileInputStyle}>Video auswählen*</label>              
          </GridItem>
          <GridItem xs={5} sm={5}></GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={11}>
            <FormGroup>
              <Image style={{width: this.state.imageWidth, heigth: this.state.imageHeight}} className={"Image"} src={this.state.imageFile}/>
                <Rnd 
                  position={{x:this.state.qrposx, y: this.state.qrposy}}
                  size={{width:this.state.qrSize, height: this.state.qrSize}}
                  bounds={".Image"} 
                  onDragStop={(e, d) => { 
                    this.setState({
                      qrposx: d.x,
                      qrposy: d.y,
                      qrposxpercentage: 100*(d.x / (this.state.imageWidth-this.state.qrSize)), // Relative width of image
                      qrposypercentage: 100*(d.y / (this.state.imageHeight-this.state.qrSize))
                    })
                    }}>
                  <img style={{height:this.state.qrSize, width:this.state.qrSize}} src={qrcode} alt="QR Code Position"  title="QR Code Position"  className={classes.dragme}/>
                </Rnd>
              </FormGroup> 
          </GridItem>
          <GridItem xs={5} sm={5}></GridItem>
          <GridItem xs={2} sm={2}>     
            <input
              onChange={this.handleImageFileChange}
              type="file"
              className={classes.customFileInputInvisible}
              id="imageInput"
            />
            <label htmlFor="imageInput" className={classes.customFileInputStyle}>Bild auswählen*</label> 
          </GridItem>
          <GridItem xs={5} sm={5}></GridItem>
        </GridContainer>

        <GridContainer justify="center">
          <GridItem xs={12}><h4 className={classes.infoText}>Name des Inhalts*</h4></GridItem>
          <GridItem xs ={12}>
            <BasicCustomInput
              labelText=""
              id="displayname"
              formControlProps={{
                fullWidth: true
              }}
              onChange={this.handleNameChange("displayName")}
              value={this.state.displayName}
            />
          </GridItem>
          <GridItem xs={12}><h4 className={classes.infoText}>Emailadresse für den Empfang des physischen Markers, inkl. QR-Code*</h4></GridItem>
          <GridItem xs={12}>
        <BasicCustomInput
          labelText=""
          id="email"
          formControlProps={{
            fullWidth: true
          }}
          onChange={this.handleChange("email")}
          value={this.state.email}
        />
        </GridItem>
        {/*<GridItem xs={12}>
          <h4 className={classes.infoText}>Weiterleitungslink (Aktiviert den "Erfahren Sie mehr Button" in der App bei Aufruf des Inhalts und verweist bei Klick auf diese Seite)</h4>
        </GridItem>
        <GridItem xs={12}>
          <BasicCustomInput
            labelText=""
            id="actionData"
            formControlProps={{
              fullWidth: true
            }}
            onChange={this.handleChange("actionData")}
            value={this.state.actionData}
          />
          </GridItem>*/}
        <GridItem xs={12}>
          <h4 className={classes.infoText}>Email mit mehr Informationen (Aktiviert den "Erfahren Sie mehr Button". Dieser sendet dann folgende Email.)</h4>
        </GridItem>
        <GridItem xs={12}>
        <Editor
          editorState={this.state.editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor-custom"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'textAlign', 'colorPicker', 'link'],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
        />
        </GridItem>
        <GridItem xs={12}>
          <h4 className={classes.infoText}>Beschreibung</h4>
        </GridItem>
        <GridItem xs ={12}>
          <textarea 
            style={{backgroundColor: "white", width:"100%", border:"#cccccc 1px solid", borderRadius: "4px", padding: "0.429em 0.857em"}}
            data-limit-rows={true}
            rows={5}
            onChange={this.handleChange("description")}
          />
        </GridItem>
        <GridItem xs={4}>
          <h4 className={classes.infoText}>Maximale Anzahl von Aufrufen</h4>
        </GridItem>
        <GridItem xs ={1}>
          <h4>{this.state.maxViews}</h4>
        </GridItem>
        <GridItem xs={7}>
            </GridItem>
        <GridItem xs={12}>
            <GridItem xs={12}>
              <GridItem xs={4}>
                <h4 className={classes.infoText}>Make Premium</h4>
             </GridItem>
            <GridItem xs={1}>
              <FormControl
                type="checkbox"
                value={this.state.premium}
                onChange={this.handleCheckboxChoice("premium")}
                placeholder=""
                />
            </GridItem>
            <GridItem xs={6}>
            </GridItem>
            </GridItem>
            <GridItem xs={12}>
            <GridItem xs={4}>
              <h4 className={classes.infoText}>Change max views</h4>
           </GridItem>
          <GridItem xs={1}>
            <TextField
              id="maxViews"
              label=""
              value={this.state.maxViews}
              onChange={this.handleChange('maxViews')}
              type="number"
              className={classes.textField}
              margin="normal"
            />
          </GridItem>
          <GridItem xs={6}>
          </GridItem>
          </GridItem>
          </GridItem>
        <GridItem xs={4}>
          <h4 className={classes.infoText}>Bitte bestätigen Sie unsere <a href="pages/datenschutz" target="_blank">Datenschutzregeln</a>*</h4>
        </GridItem>
        <GridItem xs={1}>
          <FormControl
            type="checkbox"
            value={this.state.datalaws}
            onChange={this.handleCheckboxChoice("datalaws")}
            placeholder=""
            />
        </GridItem>
        <GridItem xs={7}>
        </GridItem>
        <GridItem xs={12}>
          <Button 
            round 
            color="primary" 
            disabled={!this.validateForm()}
            onClick={this.handleSubmit}
            >
            Erstellen
          </Button>
        </GridItem>
      </GridContainer>

        </CardBody>
        </Card>
      </div>
      </div>
      </MediaQuery>
      <MobileMessage/>
    </div>
    );
  }
}

TestNow.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(privateUploadPageStyle)(TestNow)