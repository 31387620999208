import React from "react";
import PropTypes from "prop-types";
import { WithContext as ReactTags } from 'react-tag-input';

// aws immport
//import s3Upload from "../../libs/awsLib"
import { Auth } from "aws-amplify";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "../../../containers/DragQRCode.css"  // Needed for the libary used to make the qr code dragable
import '../../../assets/components/tagStyle.css';
import Button from "../../../components/CustomButtons/Button.jsx";

// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import BasicCustomInput from "../../../components/BasicCustomInput/BasicCustomInput.jsx";

import Sidebar from "../../../components/Sidebar/Sidebar.jsx";

import privateUploadPageStyle from "../../../assets/views/privateUploadPageStyle.jsx";
import { Card } from "@material-ui/core";
import CardBody from "../../../components/Card/CardBody";
import { ClipLoader } from 'react-spinners';

import awsmobile from '../../../aws-exports';
import AWSAppSyncClient from "aws-appsync";
import gql from 'graphql-tag'
import * as mutations from "../../../graphql/mutations"

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class NewCampaign extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // File Upload paramteres
      bucket: "clipthis-app-api-prod-attachmentsbucket-x8ozzjtziq7v",
      region: "eu-central-1",

      // General paramters
      isLoading: false,           // Showing rotating loading spinner
      userId: this.props.userName,
      title: "Campaign Title",
      description: "Keine Beschreibung",
      isPublic: false,
      items: [],
      searchKeywords: "Clipthis",
      campaignTags: [{id: 'ClipThis', text: 'ClipThis'}], // create array of strings tags.map(i => i.text);
      owner: "Campaign Owner",
      email: this.props.email,

    };

    //tags
    this.handleTagDelete = this.handleTagDelete.bind(this)
    this.handleTagAddition = this.handleTagAddition.bind(this);
    this.handleTagDrag = this.handleTagDrag.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
    
    this.nextPath = this.nextPath.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleTagDelete(i) {
    const { campaignTags } = this.state;
    this.setState({
      campaignTags: campaignTags.filter((tag, index) => index !== i),
    });
  }

  handleTagAddition(tag) {
    this.setState(state => ({ campaignTags: [...state.campaignTags, tag] }));
   }
 
  handleTagDrag(tag, currPos, newPos) {
    const campaignTags = [...this.state.campaignTags];
    const newTags = campaignTags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ campaignTags: newTags });
  }
 
  handleTagClick(index) {
    console.log('The tag at index ' + index + ' was clicked');
  }

  validateForm() {
    let valid = true;

    return valid;
  }

  nextPath(path) {
    this.props.history.push({
    pathname: path
    });
  }

  handleSubmit = async ({ token, error }) => {

    // Activate loading spinner
    this.setState({ isLoading: true });
  
    try { 
      
      // Add new campaign to database
      // Get campaign details
      const { title, description, isPublic, items, searchKeywords, campaignTags, owner} = this.state;
      if(title===""){
        alert("Kampagnen Name muss angegeben werden.")
        return
      }
      if(description===""){
        description = null
      }
      if(userDisplayName ===""){
        alert("Bitte geben Sie einen Anzeigenamen an.")
        return
      }
      const userDisplayName = owner
      const tags = campaignTags.map(i => i.text);
      const { identityId } = await Auth.currentCredentials();
      const userId = identityId;
      
      const client = new AWSAppSyncClient({
        url: awsmobile.aws_appsync_graphqlEndpoint,
        region: awsmobile.aws_appsync_region,
        auth: {
          type: awsmobile.aws_appsync_authenticationType,
          apiKey: awsmobile.aws_appsync_apiKey,
        },
        complexObjectsCredentials: () => Auth.currentCredentials(),
      });

      const result = await client.mutate({
        mutation: gql(mutations.createClipThisCampaignsDev),
        variables: {
          input: {
            title,
            userId, 
            description,
            isPublic,
            items, 
            searchKeywords,
            tags,
            userDisplayName
          }
        }
      });
            
      // Redirect to homepage
      this.nextPath("/pages/listcampaigns");
      alert("Glückwunsch. Ihre Kampagne wurde erfolgreich erstellt. Legen Sie nun Inhalte dafür an.");
    } catch (e) {
      alert(e);
      // Turn of spinner loader
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { tags } = this.state;
    return (
      <div className={classes.content}>
      <GridContainer>
        <Sidebar />
      </GridContainer>
      <div className={classes.spinner}>
        <ClipLoader
          className={classes.spinner}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={this.state.isLoading}
        /> </div>
        <div className={classes.container}>
        <Card>
        <CardBody>
        <GridContainer>
          <GridItem xs={6}><h4 className={classes.infoText}>Kampagnen Name</h4></GridItem>
          <GridItem xs ={6}>
            <BasicCustomInput
              labelText={"Kampagnen Name"}
              id="title"
              formControlProps={{
                fullWidth: true
              }}
              onChange={this.handleChange("title")}
              value={this.state.title}
            />
          </GridItem>
          <GridItem xs={6}><h4 className={classes.infoText}>Angezeigter Username</h4></GridItem>
          <GridItem xs ={6}>
            <BasicCustomInput
              labelText={"Angezeigter Eigentümer"}
              id="owner"
              formControlProps={{
                fullWidth: true
              }}
              onChange={this.handleChange("owner")}
              value={this.state.owner}
            />
          </GridItem>
        <GridItem xs={6}><h4 className={classes.infoText}>Beschreibung</h4></GridItem>
          <GridItem xs ={6}>
            <textarea 
              style={{backgroundColor: "white", width:"100%", border:"#cccccc 1px solid", borderRadius: "4px", padding: "0.429em 0.857em"}}
              data-limit-rows={true}
              rows={5}
              onChange={this.handleChange("description")}
            />

          </GridItem>
        <GridItem xs={6}><h4 className={classes.infoText}>Tags</h4></GridItem>
          <GridItem xs ={6}>
            <ReactTags
              tags={this.state.campaignTags}
              // suggestions={suggestions}
              delimiters={delimiters}
              handleDelete={this.handleTagDelete}
              handleAddition={this.handleTagAddition}
              handleDrag={this.handleTagDrag}
              handleTagClick={this.handleTagClick}
            />  
          </GridItem>
        <GridItem>
          <Button 
            round 
            color="primary" 
            onClick={this.handleSubmit}
            disabled={!this.validateForm}>
            Erstellen
          </Button>
        </GridItem>
      </GridContainer>

        </CardBody>
        </Card>
      </div>
      </div>
    );
  }
}

NewCampaign.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(privateUploadPageStyle)(NewCampaign)