import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import 'react-table/react-table.css';
import MediaQuery from "react-responsive";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from '@material-ui/icons/Edit';
import BulletListIcon from '@material-ui/icons/FormatListBulleted';
import AddIcon from '@material-ui/icons/AddCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Card } from "@material-ui/core";
import { ClipLoader } from 'react-spinners';

// Custom components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import listItemsPageStyle from "../../../assets/views/listItemsPageStyle.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Sidebar from "../../../components/Sidebar/Sidebar.jsx";
import CardBody from "../../../components/Card/CardBody";
import GridItem from "../../../components/Grid/GridItem.jsx";

// AWS App Sync
import AWSAppSyncClient from "aws-appsync";
import awsmobile from '../../../aws-exports';
import gql from 'graphql-tag';
import { Auth } from "aws-amplify";

import {getCampaigns} from '../../../graphql/queries'
import MobileMessage from "../../../components/MobileMessage/MobileMessage.jsx";

// import NewItem from "../../../../../clipthis-web/clipthis-app-client/src/containers/NewItem.js";

class ClipThisItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // File Upload paramteres
      bucket: "clipthis-app-api-prod-attachmentsbucket-x8ozzjtziq7v",
      region: "eu-central-1",
      visibility: "private",

      isLoading: true,
      items: [],
      data: [],
      isModalOpen: false
    };
    this.loadCampaigns = this.loadCampaigns.bind(this);
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    window.scrollTo(0, 0)
  }

  componentWillMount(){

    this.setState({isLoading: true});
    this.loadCampaigns();
  };

  refresh(){
    this.setState({isLoading: true});
    this.loadCampaigns();
  };

  async loadCampaigns(){
  
    // Query using a parameter
    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey,
      },
      complexObjectsCredentials: () => Auth.currentCredentials(),
      });
    
    client.query({
        query: gql(getCampaigns),
        variables: {userId: this.props.userName}
      }).then(({ data: { getCampaigns } }) => {
        
        const data = getCampaigns.items.map((prop, key) => {
          // Make string from tag array
          let tagString = [];
          for (var i = 0; i < prop.tags.length; i++) {
            tagString.push(prop.tags[i]+" ")
            }
    
          return {
            title: prop.title,
            owner: prop.userDisplayName,
            description: prop.description,
            tags: tagString,
            actions: (
              // custom button actions
              <div className="actions-right">            
                {/* use this button to add a edit kind of action */}
                <Button justIcon round simple onClick={() => {
                    // Go to edit item page and feed obj data
                    this.props.history.push({
                      pathname: "/pages/editcampaign",
                      state:{
                        title: prop.title,
                        description: prop.description,
                        userId: prop.userId,
                        isPublic: prop.isPublic,
                        items: prop.items,
                        tags: prop.tags,
                        userDisplayName: prop.userDisplayName
                        }
                      })
                    }}
                  color="warning"
                  className="edit"
                  >
                  <Edit />
                </Button>{" "}
                {/* use this button to list all items */}
                <Button justIcon round simple onClick={() => {
                  this.props.history.push({
                    pathname: "/pages/listcampaignitems",
                    state:{
                      userId: prop.userId,
                      title: prop.title,
                      items: prop.items,
                      description: prop.description,
                      searchKeywords: prop.searchKeywords,
                      userDisplayName: prop.userDisplayName,
                      isPublic: prop.isPublic,
                      tags: prop.tags
                      }
                    })
                  }}
                  color="danger"
                  className="remove"
                >
                  <BulletListIcon />
                </Button>{" "}
                {/* use this button to list all items */}
                <Button justIcon round simple onClick={() => {
                  this.props.history.push({
                    pathname: "/pages/additemtocampaign",
                    state:{
                      userId: prop.userId,
                      title: prop.title,
                      items: prop.items,
                      description: prop.description,
                      searchKeywords: prop.searchKeywords,
                      userDisplayName: prop.userDisplayName,
                      isPublic: prop.isPublic,
                      tags: prop.tags
                      }
                    })
                  }}
                  color="danger"
                  className="remove"
                >
                  <AddIcon />
                </Button>{" "}
              </div>
            )
          };
        })
        this.setState({
          data:data,
          isLoading: false });
      });
  }

  handleItemClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
      <MediaQuery minWidth={1224}>
      <div className={classes.content}>
        <GridContainer>
          <Sidebar />
        </GridContainer>
        <div className={classes.container}>
          <Card>
            <CardBody>
            <div className={classes.spinner}>
            <ClipLoader
            className={classes.spinner}
            sizeUnit={"px"}
            size={150}
            color={'#123abc'}
            loading={this.state.isLoading}
            /></div>
              <GridContainer className={classes.listItemContainer}>
                <GridItem xs={11}>
                  <h2>Kampagnen</h2>
                </GridItem>
                <GridItem xs={1}>
                <div style={{algin:"right"}}>
                  <Button justIcon round simple onClick={() => {
                    this.refresh()
                    }}
                  color="primary"
                  className="remove"
                >
                  <RefreshIcon />
                </Button>
                </div>
                </GridItem>
                <GridItem xs={12}>
                <ReactTable
                  data={this.state.data}
                  noDataText={"Sie haben noch keine Kampagnen erstellt."}
                  columns={[
                    {
                      Header: "Kampagnen Name",
                      accessor: "title"
                    },
                    {
                      Header: "Besitzer",
                      accessor: "owner"
                    },
                    {
                      Header: "Beschreibung",
                      accessor: "description"
                    },
                    {
                      Header: "Tags",
                      accessor: 'tags'
                    },
                    {
                      Header: "Aktionen",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultSorted={[
                    {
                      id: "title",
                      desc: false
                    }
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom={true}
                  className={classes.table}
                />
                </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </div>
        </div>
        </MediaQuery>
        <MobileMessage/>
        </div>
      );
  }
}

ClipThisItems.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(listItemsPageStyle)(ClipThisItems)
