// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://km4kvmpe2bcerestkk4pnw3mru.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-4lm24hxbrbe77ibievaq4qlrva",
    "aws_user_files_s3_bucket": "clipthis-helvetia-platform-upload",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
