import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import MediaQuery from 'react-responsive';

import {AppContainer} from "../../assets/views/material-dashboard-pro-react.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";

import homePageStyle2 from "../../assets/views/homePageStyle2.jsx";


class MobileMessage extends React.Component {

  render(){
    const { classes } = this.props;
    return( 

      <MediaQuery maxWidth={1224}>
        <div className={classes.mobileContent}>
          <AppContainer>
            <GridContainer className={classes.wowpostContainer}>
              <GridItem className={classes.wowPostItems} xs={12} sm={12} md={5}>
                <div className={classes.wowPostText}>
                  <h2 className={classes.header}>
                    Stay tuned!
                  </h2>
                  <h3 className={classes.cardTitle}>Diese Seite ist nur auf einem Desktop verfügbar. Versionen für das Ipad und IPhone erscheinen in Kürze.</h3>
                </div>
              </GridItem>
            </GridContainer>
          </AppContainer>
        </div>
      </MediaQuery>
    );
  }
}
export default withStyles(homePageStyle2)(MobileMessage);
