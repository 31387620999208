/*!

 =========================================================
 * Material Dashboard PRO React - v1.2.0 based on Material Dashboard PRO - v1.2.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

import styled from 'styled-components';

const sideBarWidth = 260;
const largeScreenSideBar = 1170 + sideBarWidth;
const mediumScreenSideBar = 970 + sideBarWidth;

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

const containerFluid = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  "&:before,&:after": {
    display: "table",
    content: '" "'
  },
  "&:after": {
    clear: "both"
  }
};

const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  "@media (min-width: 768px)": {
    width: "750px"
  },
  "@media (min-width: 992px)": {
    width: "970px"
  },
  "@media (min-width: 1200px)": {
    width: "1170px"
  },
  "&:before,&:after": {
    display: "table",
    content: '" "'
  },
  "&:after": {
    clear: "both"
  }
};
//used in Home2
const AppContainer = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  &:before,&:after {
    display: table;
    content: " ";
  }
  &:after {
    clear: both;
  }
`;
const AppContent = styled.div`
  min-height: calc(100vh - 80px);
  position: relative;
  z-index: 4;
  margin-top: 71px;
  background-color: #f9f9f9;
`;

//for old page
// const AppContent = styled.div`
//   min-height: calc(100vh - 80px);
//   position: relative;
//   z-index: 4;
// `;

const card = {
  display: "inline-block",
  position: "relative",
  width: "100%",
  margin: "25px 0",
  boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  borderRadius: "6px",
  color: "rgba(0, 0, 0, 0.87)",
  background: "#fff"
};

const defaultFont = {
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: "300",
  lineHeight: "1.5em"
};

const colors = {
  primaryColor: "#005da6",
  warningColor: "#ff9800",
  dangerColor: "#f44336",
  successColor: "#4caf50",
  infoColor: "#00acc1",
  roseColor: "#c71214",
  grayColor: "#999999",
  lightgrayColor: "#F5F5F7",
  primaryButtonColor: "#005da6",
  primaryButtonColorHover: "#005da6",
  secondaryButtonColor: "#8FD8D2",
  thirdButtonColor: "#DBC239",
  forthButtonColor: "#FEDCD2",
}

const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
};

const appBoxShadow = {
  primaryBoxShadow: "0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)",
  defaultBoxShadow: {
    border: "0",
    borderRadius: "3px",
    boxShadow:
      "0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    padding: "10px 0",
    transition: "all 150ms ease 0s"
  } 
}

//for now it is ot used, later we will replace with old card headers
const appCardHeader = {
  warningCardHeader: {
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    boxShadow: appBoxShadow.warningBoxShadow
  },
  successCardHeader: {
    background: "linear-gradient(60deg, #66bb6a, #43a047)",
    boxShadow: appBoxShadow.successBoxShadow
  },
  dangerCardHeader: {
    background: "linear-gradient(60deg, #ef5350, #e53935)",
    boxShadow: appBoxShadow.dangerBoxShadow
  },
  infoCardHeader: {
    background: "linear-gradient(60deg, #26c6da, #00acc1)",
    boxShadow: appBoxShadow.infoBoxShadow
  },
  primaryCardHeader: {
    background: "linear-gradient(60deg, #005da6, #005da6)",
    boxShadow: appBoxShadow.primaryBoxShadow
  },
  roseCardHeader: {
    background:  "linear-gradient(60deg, #ec407a, #d81b60)",
    boxShadow: appBoxShadow.roseBoxShadow
  },
  lightgrayCardHeader: {
    background: "linear-gradient(60deg, #ffffff, #F5F5F7)",
    boxShadow: appBoxShadow.lightgrayBoxShadow
  }
}

// new card headers
const warningCardHeader = {
  background: "linear-gradient(60deg, #ffa726, #fb8c00)",
  boxShadow: appBoxShadow.warningBoxShadow
};
const successCardHeader = {
  background: "linear-gradient(60deg, #66bb6a, #43a047)",
  boxShadow: appBoxShadow.successBoxShadow
};
const dangerCardHeader = {
  background: "linear-gradient(60deg, #ef5350, #e53935)",
  boxShadow: appBoxShadow.dangerBoxShadow
};
const infoCardHeader = {
  background: "linear-gradient(60deg, #26c6da, #00acc1)",
  boxShadow: appBoxShadow.infoBoxShadow
};
const primaryCardHeader = {
  background: "linear-gradient(60deg, #005da6, #005da6)",
  boxShadow: appBoxShadow.primaryBoxShadow
};
const roseCardHeader = {
  background:  "linear-gradient(60deg, #ec407a, #d81b60)",
  boxShadow: appBoxShadow.roseBoxShadow
};

const lightgrayCardHeader = {
  background: "linear-gradient(60deg, #ffffff, #F5F5F7)",
  boxShadow: appBoxShadow.lightgrayBoxShadow
};

const cardHeader = {
  margin: "-20px 15px 0",
  borderRadius: "3px",
  padding: "15px"
};

const tooltip = {
  padding: "10px 15px",
  minWidth: "130px",
  color: "#FFFFFF",
  lineHeight: "1.7em",
  background: "rgba(85,85,85,0.9)",
  border: "none",
  borderRadius: "3px",
  opacity: "1!important",
  boxShadow:
    "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
  maxWidth: "200px",
  textAlign: "center",
  fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  textShadow: "none",
  textTransform: "none",
  letterSpacing: "normal",
  wordBreak: "normal",
  wordSpacing: "normal",
  wordWrap: "normal",
  whiteSpace: "normal",
  lineBreak: "auto"
};

const title = {
  color: "#3C4858",
  textDecoration: "none",
  fontWeight: "300",
  marginTop: "30px",
  marginBottom: "25px",
  minHeight: "32px",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  "& small": {
    color: "#777",
    fontSize: "65%",
    fontWeight: "400",
    lineHeight: "1"
  }
};

const cardTitle = {
  ...title,
  marginTop: "0",
  marginBottom: "3px",
  minHeight: "auto",
  "& a": {
    ...title,
    marginTop: ".625rem",
    marginBottom: "0.75rem",
    minHeight: "auto"
  }
};

export {
  sideBarWidth,
  largeScreenSideBar,
  mediumScreenSideBar,
  //variables
  container,
  containerFluid,
  card,
  defaultFont,
  roseCardHeader,
  lightgrayCardHeader,
  // new card header colors
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  cardHeader,
  tooltip,
  title,
  cardTitle,
  //new styles
  AppContainer,
  AppContent,
  colors,
  appBoxShadow,
  appCardHeader,
  boxShadow,
  transition
};
