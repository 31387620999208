import React, { Component } from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import 'react-table/react-table.css';
import MediaQuery from "react-responsive";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image'
import VideoIcon from '@material-ui/icons/PlayCircleFilled'
import Dashboard from '@material-ui/icons/Dashboard';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from "@material-ui/icons/AddCircle"
import MobileMessage from "../../../components/MobileMessage/MobileMessage.jsx";

// Custom components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CardBody from "../../../components/Card/CardBody";
import { Card } from "@material-ui/core";
import GridItem from "../../../components/Grid/GridItem.jsx";

import AWSAppSyncClient from "aws-appsync";
import awsmobile from '../../../aws-exports';
import gql from 'graphql-tag';
import { Auth } from "aws-amplify";
import {getItems} from '../../../graphql/queries';
import {updateClipThisCampaignsDev, updateClipThisItem} from '../../../graphql/mutations';

// Style
import listItemsPageStyle from "../../../assets/views/listItemsPageStyle.jsx";

import Sidebar from "../../../components/Sidebar/Sidebar.jsx";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: "#fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
},
});


class AddItemToCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // File Upload paramteres
      bucket: "clipthis-app-api-prod-attachmentsbucket-x8ozzjtziq7v",
      region: "eu-central-1",
      visibility: "private",

      isLoading: true,
      items: [],
      data: [],
      isModalOpen: false,
      clipThisItems: null,
      mobileOpen: false,
      miniActive: false
    };
    this.addItem = this.addItem.bind(this);
    this.editCampaign = this.editCampaign.bind(this);
    this.addItemandUpdateCampaign = this.addItemandUpdateCampaign.bind(this);
  }

  componentWillMount(){
    let currentItems = this.props.location.state.items
    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey,
      },
      complexObjectsCredentials: () => Auth.currentCredentials(),
      });
      
    try{
      this.setState({
        isLoading: true
      });
      client.query({
        query: gql(getItems),
        variables: {userId: this.props.userName}
      }).then(({ data: { getItems } }) => {
        
        const data = getItems.items.filter(item => !currentItems.includes(item.clipThisId)).map((prop, key) => {
      
          return {
            id: key,
            clipThisId: prop.clipThisId,
            name: prop.displayName,
            actions: (
              // custom button actions
              <div className="actions-right">
                {/* use this button to add a dashboard kind of action */}              
                <Button justIcon round simple onClick={() => {
                    // Go to edit item page and feed obj data
                    this.addItemandUpdateCampaign(prop.clipThisId);
                    }}
                  color="warning"
                  className="edit"
                  >
                  <AddIcon />
                </Button>{" "}
              </div>
            )
          };
        })
    
        this.setState({ 
          data: data,
          isLoading: false
         });
      });
    }
    catch(e){console.log("Error ", e)}
  };

  addItemandUpdateCampaign(clipThisId){

    let items = this.props.location.state.items;
    items.push(clipThisId);
    console.log("Items ", items)
    console.log("Length ", items.length)
    this.addItem(items);

    var i;
    for (i=0; i<items.length; i++){
      console.log("ID ", items[i])
      this.editCampaign(items[i], items);}
  }

  async editCampaign(clipThisId, items){
    let campaign = {};
    campaign["description"] = this.props.location.state.description;
    campaign["searchKeywords"] = this.props.location.state.searchKeywords;
    campaign["title"] = this.props.location.state.title;
    campaign["userDisplayName"] = this.props.location.state.userDisplayName;
    campaign["isPublic"] = this.props.location.state.isPublic;
    campaign["userId"] = this.props.location.state.userId;
    campaign["tags"] = this.props.location.state.tags;
    campaign["items"] = items;
    campaign = JSON.stringify(campaign);
    const userId = this.props.location.state.userId;
    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey,
      },
      complexObjectsCredentials: () => Auth.currentCredentials(),
      });
    
    try{
      this.setState({
        isLoading: true
      });
      const edit = true;
      const result = await client.mutate({
        mutation: gql(updateClipThisItem),
        variables: {
          input: {
            clipThisId,
            campaign,
            userId,
            edit
          }
        }
      });
    }
    catch(e){console.log(e)}
  }

  async addItem(items){

    const userId = this.props.location.state.userId;
    const title = this.props.location.state.title;

    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey,
      },
      complexObjectsCredentials: () => Auth.currentCredentials(),
      });
    
    try{
      this.setState({
        isLoading: true
      });
      var updateClipThisCampaignsDevInput = {};
      updateClipThisCampaignsDevInput["userId"] = userId;
      updateClipThisCampaignsDevInput["title"] = title;
      updateClipThisCampaignsDevInput["items"] = items
      client.mutate({
        mutation: gql(updateClipThisCampaignsDev),
        variables: {
          input: updateClipThisCampaignsDevInput}
      }).then(({ data: { updateClipThisCampaignsDev } }) => {
        alert("Inhalt erfolgreich zur Kampagne hinzugefügt.")

        this.props.history.push({
          pathname: "/pages/listcampaignitems",
          state:{
            userId: this.props.location.state.userId,
            items: items,
            title: title
            }
          })
      })
    }
    catch(e){console.log(e)}
  }


  componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    window.scrollTo(0, 0)
  }

  handleItemClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
      <MediaQuery minWidth={1224}>
      <div className={classes.content}>
        <GridContainer>
          <Sidebar/>
        </GridContainer>
       <div className={classes.container}>
        <Card>
          <CardBody>
            <GridContainer className={classes.listItemContainer}>  
              <div style={{textAlign: "left"}}>
                <h2>Inhalt zu Kampagne "{this.props.location.state.title}" hinzufügen</h2>
              </div>
              <GridItem xs={12}>
                <ReactTable
                  data={this.state.data}
                  noDataText={"No items found"}
                  columns={[
                    {
                      Header: "ItemName",
                      accessor: "name"
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={30}
                  defaultSorted={[
                    {
                      id: "name",
                      desc: false
                    }
                  ]}
                  showPaginationBottom={true}
                  className={classes.table}
                  />
              </GridItem>
             </GridContainer>
            </CardBody>
          </Card>
        </div>
        </div>
        </MediaQuery>
        <MobileMessage/>
      </div>
      );
  }
}


AddItemToCampaign.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(listItemsPageStyle)(AddItemToCampaign)
