import React, { Component } from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import 'react-table/react-table.css';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Edit from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image'
import DashboardIcon from "@material-ui/icons/Dashboard"
import VideoIcon from '@material-ui/icons/PlayCircleFilled'
import DownIcon from '@material-ui/icons/ArrowDownward'
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import { ClipLoader } from 'react-spinners';
import GridItem from "../../../components/Grid/GridItem.jsx";
import MediaQuery from "react-responsive";
// Custom components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CardBody from "../../../components/Card/CardBody";
import { Card } from "@material-ui/core";
import { Player } from 'video-react';
import MobileMessage from "../../../components/MobileMessage/MobileMessage.jsx";

// AWS App Sync
import config from "../../../config.js"
import AWSAppSyncClient from "aws-appsync";
import awsmobile from '../../../aws-exports';
import gql from 'graphql-tag';
import { Storage, Auth } from "aws-amplify";
import {getItems} from '../../../graphql/queries'
import {deleteClipThisItem} from '../../../graphql/mutations'

// Style
import listItemsPageStyle from "../../../assets/views/listItemsPageStyle.jsx";

import Sidebar from "../../../components/Sidebar/Sidebar.jsx";

class ClipThisItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // File Upload paramteres
      bucket: config.s3.BUCKET,
      region: "eu-central-1",
      level: "private",

      isLoading: true,
      items: [],
      data: [],
      isImageModalOpen: false,
      isVideoModalOpen: false,
      clipThisItems: null,
      mobileOpen: false,
      miniActive: false,
      imageUrl: null,
      videoUrl: null,
      videoFile: null,
      image:null,
      imageHeight:200,
      imageWidth: 200,
      videoHeight: 200,
      videoWidth: 200
    };
  }

  componentDidMount(){
    window.scrollTo(0, 0)
    this.setState({isLoading: true})
    this.loadItems();
  }

  refresh(){
    this.setState({isLoading: true});
    this.loadItems();
  };

  async loadItems(){

    // Query items
    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey,
      },
      complexObjectsCredentials: () => Auth.currentCredentials(),
      });
      
    try{
      this.setState({
        isLoading: true
      });
      client.query({
        query: gql(getItems),
        variables: {userId: this.props.userName}
      }).then(({ data: { getItems } }) => {
        
        const data = getItems.items.map((prop, key) => {
          return {
            id: key,
            clipThisId: prop.clipThisId,
            userId: prop.userId,
            name: prop.displayName,
            views: prop.views,
            maxViews: prop.maxViews,
            campaign: prop.campaign,
            actions: (
              // custom button actions
              <div className="actions-right">
              <Button justIcon round simple 
                  onClick={() => {this.handleQRDownload(prop.clipThisId)}}              
                  color="info"
                  className="edit"
                  >
                  <DownIcon />
                </Button>
                {" "}
                <Button justIcon round simple 
                  onClick={() => {this.handleVideoModal(prop.videoFileLocation)}}              
                  color="info"
                  className="edit"
                  >
                  <VideoIcon />
                </Button>
                {" "}
                <Button justIcon round simple 
                  onClick={() => {this.handleImageModal(prop.imageFileLocation)}}             
                  color="info"
                  className="edit"
                  >
                  <ImageIcon />
                </Button>{" "}
                {/* use this button to add a dashboard kind of action */}
                {(["eu-central-1:8c539b15-4d7d-40fc-bd74-1f9b374bf9cb", "eu-central-1:09481023-2f88-402d-a8b1-04716034c419", "eu-central-1:3ab10b62-5486-40a4-bed7-abbd789eba35", "eu-central-1:872de90a-e739-4f6a-9ef1-006ea9fb7149", "eu-central-1:02e20bbc-3c60-48ce-a483-793a763290be"].indexOf(prop.userId)>=0)
                ?
                  <Button justIcon round simple onClick={() => {
                    // Go to edit item page and feed obj data
                    this.props.history.push({
                      pathname: "/pages/itemdashboard",
                      state:{
                        displayName: prop.displayName,
                        analytics: prop.analytics}
                      })
                    }}
                    color="info"
                    className="like"
                    >
                    <DashboardIcon />
                  </Button>
                : ""}
                {/* use this button to add a edit kind of action */}
                <Button justIcon round simple onClick={() => {
                    // Go to edit item page and feed obj data
                    this.props.history.push({
                      pathname: "/edittest",
                      state:{
                        clipThisId: prop.clipThisId,
                        maxViews: prop.maxViews,
                        views: prop.views,
                        analytics: prop.analytics,
                        actionData: prop.actionData,
                        actionType: prop.link,
                        actionName: prop.actionName,
                        displayName: prop.displayName,
                        active: prop.active,
                        premium: prop.premium,
                        email: prop.email,
                        qrposx: prop.qrposx,
                        qrposy: prop.qrposy,
                        createdAt: prop.createdAt,
                        description: prop.description
                        }
                      })
                    }}
                  color="warning"
                  className="edit"
                  >
                  <Edit />
                </Button>{" "}
                {/* use this button to remove the data row */}
                <Button justIcon round simple onClick={() => {
                    var data = this.state.data;
                    data.find((o, i) => {
                      if (o.id === key) {
                        // here you should add some custom code so you can delete the data
                        // from this component and from your server as well
                        let obj = data.find(o => o.id === key);
    
                        // delete from server
                        const confirmed = window.confirm(
                          "Sind Sie sich sicher, dass Sie diesen Inhalt löschen möchten?"
                        );
    
                        if (!confirmed) {
                          return;
                        }
    
                        try {
                          this.deleteItem(obj.clipThisId, obj.campaign);
                        } catch (e) {
                          alert(e);
                          return;
                        }
                        // delete from data
                        if(obj.campaign =="{}" | obj.campaign ==null){
                        data.splice(i, 1);
                        return true;}else{return false;}
                      }
                      return false;
                    });
                    this.setState({ data: data });
                  }}
                  color="danger"
                  className="remove"
                >
                  <DeleteIcon />
                </Button>{" "}
              </div>
            )
          };
        })
    
        this.setState({ 
          data: data,
          isLoading: false
         });
      });
    }
    catch(e){console.log("Error ", e)}
  };

  handleQRDownload = async (clipThisId) => {
    const bucket = "clipthis-qrcodes"
    const region = "eu-central-1"
    const level = "private"
    const qrUrl = await Storage.get(clipThisId+".jpg", { bucket, region, level });

    setTimeout(() => {
      const response = {
        file: qrUrl,
      };
      // now, let's download:
      window.open(response.file);
      // window.location.href = response.file;
    }, 100);
  }

  getImageWidthHeight(url, callback) {
    var img = document.createElement("img");
    img.onload = function() {
      var w  = img.naturalWidth  || img.width;
      var h = img.naturalHeight || img.height;
      callback({width: w, height: h});
    }
    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;
  };

  handleImageModal = async (imageLocation) => {

    var infos = JSON.parse(imageLocation);

    const bucket = this.state.bucket;//infos["bucket"];
    const region = this.state.region;//infos["region"];
    const level = this.state.level;
    let imageKey = infos["key"];
    imageKey = imageKey.split("/")[1];

    try{
      const imageUrl = await Storage.get(imageKey, { bucket, region, level });

      this.setState({
        image: imageUrl
      });

      this.getImageWidthHeight(imageUrl, (result) => {
        var width = 0
        var height = 0
        // Height > Width
        if(result.width <= result.height){
        // Check if height is bigger than screen
        if(result.height>600){
          height = 600
          width = height / result.height * result.width
          }else{
            width = result.width
            height = result.height
          }
        }
        // Width > Height
        else{
          // Check if width is bigger than screen
          if(result.width>600){
            width = 600
            height = width / result.width * result.height
            }
          else{
            width = result.width
            height = result.height
            }
          }
        this.setState({
          imageWidth : width,
          imageHeight: height,
        });
      })

    }catch{console.log("Error")};

    this.handleImageOpen();
  };

  handleVideoModal = async (videoLocation) => {
    var infos = JSON.parse(videoLocation);

    const bucket = this.state.bucket;
    const region = this.state.region;
    const level = this.state.level;
    const videoKey = infos["key"];

    try{
      const videoUrl = await Storage.vault.get(videoKey, {bucket, region, level});
      this.setState({
        videoFile: videoUrl,
      });
      // callback function after Video is changed
      this.getVideoWidthHeight(videoUrl, (result) => {
        this.setState({videoWidth : result.width});
        this.setState({videoHeight : result.height});
      });

    }catch{console.log("Error")};

    this.handleVideoOpen();
  };

  getVideoWidthHeight(url, callback) {
    var vid = document.createElement("VIDEO");
    vid.onloadedmetadata= function() {
      // `naturalWidth`/`naturalHeight` aren't supported on <IE9. Fallback to normal width/height
      // The natural size is the actual image size regardless of rendering.
      // The 'normal' width/height are for the **rendered** size.
      var w  = vid.videoWidth;
      var h = vid.videoHeight;
      callback({width: w, height: h});
    }
    // Setting the source makes it start downloading and eventually call `onload`
    vid.src = url;
  }  
  
  handleImageOpen = () => {
    this.setState({ isImageModalOpen: true });
  };

  handleVideoOpen = () => {
    this.setState({ isVideoModalOpen: true });
  };

  handleClose = () => {
    this.setState({ 
      isImageModalOpen: false,
      isVideoModalOpen: false,
    });
  };

  async onDelete(clipThisId){
    // Query items
    const client = new AWSAppSyncClient({
      url: awsmobile.aws_appsync_graphqlEndpoint,
      region: awsmobile.aws_appsync_region,
      auth: {
        type: awsmobile.aws_appsync_authenticationType,
        apiKey: awsmobile.aws_appsync_apiKey,
      },
      complexObjectsCredentials: () => Auth.currentCredentials(),
      });
      
    try{
      const result = await client.mutate({
        mutation: gql(deleteClipThisItem),
        variables: {
          input: {
            clipThisId}
          }
        })
        console.log(result)
      }catch (e) {
        alert(e);
        }
  };

  deleteItem(clipThisId, campaign) {
    
    if(campaign != "{}" & campaign !=null){
      alert("Inhalt ist einer Kampagne zugeordnet. Bitte erst aus Kampagne löschen.")
    }else{
      console.log("CLIPTHIS ID TO DELETE: ", clipThisId)
      this.onDelete(clipThisId)

      const bucket = this.state.bucket;
      const region = this.state.region;
      const level = this.state.visibility;
      const imageKeyjpg = clipThisId + ".jpg";
      const imageKeypng = clipThisId + ".png";
      const videoKey = clipThisId + ".mp4";
      // Delete image in private folder
      try{
        Storage.remove(imageKeyjpg, { level: 'private' });
      }catch{};
      try{
        Storage.remove(imageKeypng, { level: 'private' });
      }catch{};
      
      // Delete video in private folder
      try{
        Storage.remove(videoKey, { level: 'private' });
      }catch{};
    };

  };

  handleItemClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
      <MediaQuery minWidth={1224}>
      <div className={classes.content}>
        <GridContainer>
          <Sidebar/>
        </GridContainer>
       <div className={classes.container}>
        <Card>
        <CardBody>
          <div className={classes.spinner}>
            <ClipLoader
            className={classes.spinner}
            sizeUnit={"px"}
            size={150}
            color={'#123abc'}
            loading={this.state.isLoading}
            /></div>
          <GridContainer className={classes.listItemContainer}>  
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.isImageModalOpen}
            onClose={this.handleClose}
            style={{paddingLeft: (window.innerWidth/2-this.state.imageWidth/2)+"px", paddingTop: (window.innerHeight/2-this.state.imageHeight/2)+"px"}}
            >
            <img src={this.state.image} style={{width: this.state.imageWidth, heigth: this.state.imageHeight}}/>
          </Modal>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.isVideoModalOpen}
              onClose={this.handleClose}
              style={{paddingLeft: (window.innerWidth/2-this.state.videoWidth/2)+"px", paddingTop: (window.innerHeight/2-this.state.videoHeight/2)+"px"}}
              >
              <Player  
                src={this.state.videoFile}
                fluid={false}
                height={250}
                poster={null}/>
            </Modal>
            <GridItem xs={11}>
              <h2>Inhalte</h2>
            </GridItem>
            <GridItem xs={1}>
              <div style={{algin:"right"}}>
                <Button justIcon round simple onClick={() => {
                  this.refresh()
                  }}
                color="primary"
                className="remove"
                >
                  <RefreshIcon />
                </Button>
              </div>
            </GridItem>
            <GridItem xs={12}>
              <ReactTable
                data={this.state.data}
                noDataText={"Sie haben noch keine ClipThis Inhalte erstellt."}
                columns={[
                  {
                    Header: "Name",
                    accessor: "name"
                  },
                  {
                    Header: "Views",
                    accessor: "views"
                  },
                  {
                    Header: "Maximale Views",
                    accessor: "maxViews"
                  },
                  {
                    Header: "Aktion",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultSorted={[
                  {
                    id: "name",
                    desc: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationBottom={true}
                className={classes.table}
              />
            </GridItem>
          </GridContainer>
          </CardBody>
          </Card>
          </div>
          </div>
          </MediaQuery>
          <MobileMessage/>
          </div>
      );
  }
}

ClipThisItems.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(listItemsPageStyle)(ClipThisItems)

