// eslint-disable
// this is an auto generated file. This will be overwritten

export const getClipThisItem = `query GetClipThisItem($clipThisId: String!) {
  getClipThisItem(clipThisId: $clipThisId) {
    clipThisId
    userId
    email
    maxViews
    views
    analytics
    actionData
    actionType
    actionName
    displayName
    premium
    active
    qrposx
    qrposy
    file {
      bucket
      region
      key
    }
    videoFile {
      bucket
      region
      key
    }
    description
    createdAt
    updatedAt
    imageFileLocation
    videoFileLocation
    campaign
    webapp
  }
}
`;
export const listClipThisItems = `query ListClipThisItems(
  $filter: TableClipThisItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listClipThisItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      clipThisId
      userId
      email
      maxViews
      views
      analytics
      actionData
      actionType
      actionName
      displayName
      premium
      active
      qrposx
      qrposy
      file {
        bucket
        region
        key
      }
      videoFile {
        bucket
        region
        key
      }
      description
      createdAt
      updatedAt
      imageFileLocation
      videoFileLocation
      campaign
      webapp
    }
    nextToken
  }
}
`;
export const listClipThisItemsPerUser = `query ListClipThisItemsPerUser(
  $filter: TableClipThisItemUserIdFilterInput
  $limit: Int
  $nextToken: String
) {
  listClipThisItemsPerUser(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      clipThisId
      userId
      email
      maxViews
      views
      analytics
      actionData
      actionType
      actionName
      displayName
      premium
      active
      qrposx
      qrposy
      file {
        bucket
        region
        key
      }
      videoFile {
        bucket
        region
        key
      }
      description
      createdAt
      updatedAt
      imageFileLocation
      videoFileLocation
      campaign
      webapp
    }
    nextToken
  }
}
`;
export const listClipThisItemsPerCampaign = `query ListClipThisItemsPerCampaign(
  $filter: TableClipThisItemCampaignIdFilterInput
  $limit: Int
  $nextToken: String
) {
  listClipThisItemsPerCampaign(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      clipThisId
      userId
      email
      maxViews
      views
      analytics
      actionData
      actionType
      actionName
      displayName
      premium
      active
      qrposx
      qrposy
      file {
        bucket
        region
        key
      }
      videoFile {
        bucket
        region
        key
      }
      description
      createdAt
      updatedAt
      imageFileLocation
      videoFileLocation
      campaign
      webapp
    }
    nextToken
  }
}
`;
export const getItems = `query GetItems($userId: String!) {
  getItems(userId: $userId) {
    items {
      clipThisId
      userId
      email
      maxViews
      views
      analytics
      actionData
      actionType
      actionName
      displayName
      premium
      active
      qrposx
      qrposy
      file {
        bucket
        region
        key
      }
      videoFile {
        bucket
        region
        key
      }
      description
      createdAt
      updatedAt
      imageFileLocation
      videoFileLocation
      campaign
      webapp
    }
    nextToken
  }
}
`;
export const batchGetItems = `query BatchGetItems($clipThisIds: listofStrings) {
  batchGetItems(clipThisIds: $clipThisIds) {
    clipThisId
    userId
    email
    maxViews
    views
    analytics
    actionData
    actionType
    actionName
    displayName
    premium
    active
    qrposx
    qrposy
    file {
      bucket
      region
      key
    }
    videoFile {
      bucket
      region
      key
    }
    description
    createdAt
    updatedAt
    imageFileLocation
    videoFileLocation
    campaign
    webapp
  }
}
`;
export const getCampaigns = `query GetCampaigns($userId: String!) {
  getCampaigns(userId: $userId) {
    items {
      title
      userId
      description
      isPublic
      items
      searchKeywords
      tags
      userDisplayName
    }
    nextToken
  }
}
`;
export const listClipThisCampaignsDevs = `query ListClipThisCampaignsDevs(
  $filter: TableClipThisCampaignsDevFilterInput
  $limit: Int
  $nextToken: String
) {
  listClipThisCampaignsDevs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      title
      userId
      description
      isPublic
      items
      searchKeywords
      tags
      userDisplayName
    }
    nextToken
  }
}
`;
